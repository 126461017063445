import _ from "lodash";
import styled from "styled-components";
import PlanRegModal from "../../components/ui/modal/PlanRegModal";
import { useEffect, useRef, useState } from "react";
import apis from "../../shared/apis";
import Pagination from "../../components/ui/pagination/Pagination";
import { useQuery } from "@tanstack/react-query";
import { handleError } from "../../components/ui/err/handleError";
import { AxiosError } from "axios";
import { AxiosCommonError } from "../../shared/types";
import { useSearchParams } from "react-router-dom";
import TableUnit from "../../components/units/Management/Plan/TableUnit";
import Search from "../../components/ui/search/Search";
import * as C from "../../components/ui/common/CommonStyles";

export interface IPayPlan {
  id: string;
  title: string;
  alias: string;
  payType: string;
  payInterval: number;
  orgPrice: number;
  price: number;
  description: string;
  regDate: string;
  modDate: string;
  regUserName: string;
  modUserName: string;
}

const PlanManagement = () => {
  let total = 0;
  const size = 10;
  // 현재 페이지 상태값 및 시작 & 엑티브 페이지 상태값 저장
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [startPage, setStartPage] = useState<number>(1);
  const [active, setActive] = useState<string>("1");
  // 모달 상태값
  const [isOpen, setIsOpen] = useState<boolean>(false);
  // 생성/수정 상태값
  const [isEdit, setIsEdit] = useState<boolean>(false);
  // 수정할 요금제 id
  const [editItem, setEditItem] = useState<IPayPlan | null>(null);
  // 클라이언트단 url parameter 설정
  const [searchParams, setSearchParams] = useSearchParams("");
  const searchKeywordUrl = searchParams.get("search") || "";
  const searchTypeUrl = searchParams.get("searchType") || "";
  // 검색 버튼 클릭시, input창 값 가져오기
  const [searchInput, setSearchInput] = useState<string>("");
  // select 창 값 가져오기
  const [searchType, setSearchType] = useState<string>("");

  // 상태 변환시 input, select창 초기화
  const inputRef = useRef<HTMLInputElement>(null);
  const selectRef = useRef<HTMLSelectElement>(null);

  // 검색, 초기화시 Pagination 컴포넌트 상태 초기화
  useEffect(() => {
    setCurrentPage(1);
    setStartPage(1);
    setActive("1");

    // 사이드바 클릭시 input & select 창 초기화 안되던 버그 핸들링 💡💡💡💡💡
    if (searchKeywordUrl === "" && searchTypeUrl === "all") {
      inputRef.current!.value = "";
      selectRef.current!.value = "all";
    }

    // 새로고침시 setSearchParams 로 할당해준 url 쿼리 스트링 초기화 안되던 버그 핸들링 🐛🐛🐛🐛🐛
    if (searchKeywordUrl !== "") {
      inputRef.current!.value = searchKeywordUrl;
      selectRef.current!.value = searchTypeUrl;
      setSearchInput(searchKeywordUrl);
      setSearchType(searchTypeUrl);
    }
  }, [searchParams, searchKeywordUrl, searchTypeUrl]);

  const { data: planList, refetch } = useQuery(
    ["getPlanList", currentPage, size, searchKeywordUrl, searchTypeUrl],
    () => apis.getPlanList(currentPage, size, searchTypeUrl, searchKeywordUrl),
    {
      select: (data) => {
        total = data.data.total;
        return data.data.result;
      },
      onError: (error: AxiosError<AxiosCommonError>) => {
        handleError(error);
      },
    }
  );

  const openModal = () => {
    setIsOpen(true);
  };

  const options = [
    { value: "", label: "전체" },
    { value: "정기결제", label: "구독 결제" },
    { value: "단건결제", label: "단건 결제" },
  ];

  const onChangeSearchType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSearchType(e.target.value);
  };
  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setSearchParams(
        `searchType=${searchType}&search=${e.currentTarget.value}`
      );
    }
  };

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
  };

  const onClickSearch = () => {
    setSearchParams(`searchType=${searchType}&search=${searchInput}`);
  };

  const onClickPlan = (plan: IPayPlan) => {
    return () => {
      setIsEdit(true);
      setEditItem({ ...plan });
      openModal();
    };
  };

  return (
    <div>
      <C.TitleBox>
        <div className="title">요금제 생성/관리</div>
      </C.TitleBox>
      <C.ListBox>
        <C.ControlWrap>
          <Search
            total={total}
            options={options}
            selectRef={selectRef}
            inputRef={inputRef}
            onKeyDown={onKeyDown}
            onChangeSearchType={onChangeSearchType}
            onChangeInput={onChangeInput}
            onClickSearch={onClickSearch}
            placeholder="요금제명, 요금제 메모로 검색"
          />
          <RegBox>
            <button
              onClick={() => {
                setIsEdit(false);
                openModal();
              }}
            >
              신규 요금제 생성
            </button>
          </RegBox>
        </C.ControlWrap>
        <TableWrap>
          <TableUnit data={planList} onClickPlan={onClickPlan} />
          {total > 0 ? (
            <Pagination
              total={total}
              size={size}
              setCurrentPage={setCurrentPage}
              startPage={startPage}
              setStartPage={setStartPage}
              active={active}
              setActive={setActive}
            />
          ) : null}
        </TableWrap>
      </C.ListBox>
      <PlanRegModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        editItem={editItem}
        setEditItem={setEditItem}
        refetch={refetch}
      />
    </div>
  );
};

export default PlanManagement;

const RegBox = styled.div`
  button {
    width: 11rem;
    height: 4rem;
    background-color: ${(props) => props.theme.color.black};
  }
`;

const TableWrap = styled.div`
  width: 100%;
`;
