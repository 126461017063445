const Theme = {
  breakpoints: {
    Mobile: "570px",
    TabletMin: "768px",
    TabletMax: "1023px",
    Desktop: "1024px",
  },

  // @media (max-width: ${(props) => props.theme.breakpoints.TabletMin}) {
  //   TabletMin 이하 화면에서는 display:none;
  //   display: none;
  // }

  color: {
    white: "#ffffff",
    black: "#000000",
    red: "#fc5f64",
    error: "#fa5963",
    green: "#107c41",
    blue: "#007bff",
    gray: "#808080",
    purple: "#800080",
    bg: {
      200: "#f5f7fa",
      400: "#f6f7fb",
      600: "#353535",
    },
    shadow: {
      blue: "rgba(0, 123, 255, 0.25)",
      gray: "#ccd3d8",
    },
  },

  border: {
    gray1: "1px solid #e1e1e1",
    gray2: "1px solid #e9edf3",
    blue1: "1px solid #007bff",
    blue2: "2px solid #007bff",
  },
};

export default Theme;
