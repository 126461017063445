import { AxiosError } from "axios";
import { AxiosCommonError } from "../../../shared/types";
import Swal from "sweetalert2";

interface ErrorDetail {
  title: string;
  text: string;
}

export const handleError = (error: AxiosError<AxiosCommonError>) => {
  const errorMessage =
    error.response?.data.message || error.response?.data.errorMessage;

  const errorDetails: { [key: string]: ErrorDetail } = {
    // -- 로그인 에러 메세지
    "등록되지 않은 E-MAIL 입니다.": {
      title: "E-MAIL 오류",
      text: "등록되지 않은 E-MAIL 입니다",
    },
    "잘못된 비밀번호입니다.": {
      title: "비밀번호 오류",
      text: "잘못된 비밀번호입니다.",
    },
    "승인되지 않은 계정입니다.": {
      title: "계정 승인 오류",
      text: "승인되지 않은 계정입니다. 관리자에게 문의 바랍니다.",
    },
    "중복된 이메일이 존재합니다.": {
      title: "중복 이메일 오류",
      text: "중복된 이메일이 존재합니다.",
    },
    "중복된 와이파이 맥번호가 존재합니다.": {
      title: "중복 오류",
      text: "중복된 와이파이 맥번호가 존재합니다.",
    },
    "중복된 이더넷 맥번호가 존재합니다.": {
      title: "중복 오류",
      text: "중복된 이더넷 맥번호가 존재합니다.",
    },
  };

  if (typeof errorMessage === "string" && errorMessage in errorDetails) {
    const errorInfo = errorDetails[errorMessage];

    Swal.fire({
      title: errorInfo.title,
      text: errorInfo.text,
      icon: "error",
      confirmButtonText: "확인",
    });
  } else {
    Swal.fire({
      title: "알 수 없는 에러 발생",
      text: "알 수 없는 에러가 발생했습니다.",
      icon: "error",
      confirmButtonText: "확인",
    });
  }
};
