import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import DeviceServiceModal from "../../components/ui/modal/DeviceServiceModal";
import Pagination from "../../components/ui/pagination/Pagination";
import { useQuery } from "@tanstack/react-query";
import apis from "../../shared/apis";
import { handleError } from "../../components/ui/err/handleError";
import { AxiosError } from "axios";
import { AxiosCommonError } from "../../shared/types";
import TableUnit from "../../components/units/Management/DeviceService/TableUnit";
import { useSearchParams } from "react-router-dom";
import Search from "../../components/ui/search/Search";
import * as C from "../../components/ui/common/CommonStyles";

export interface IPlan {
  id: string;
  title: string;
  alias: string;
  payInterval: number;
  description: string;
}

export interface IDeviceService {
  id: string;
  mac: string;
  deviceUsername: string;
  deviceType: string;
  freeTrialPeriod: number;
  subPlan: IPlan[];
  singlePlan: IPlan[];
  curPlan: IPlan;
  curPlanStartDate: string;
  curPlanEndDate: string;
  regDate: string;
  regUsername: string;
}

const ServiceManagement = () => {
  let total = 0;
  const size = 10;
  // 현재 페이지 상태값 및 시작 & 엑티브 페이지 상태값 저장
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [startPage, setStartPage] = useState<number>(1);
  const [active, setActive] = useState<string>("1");
  const [isOpen, setIsOpen] = useState(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [editItem, setEditItem] = useState<IDeviceService | null>(null);
  // 클라이언트단 url parameter 설정
  const [searchParams, setSearchParams] = useSearchParams("");
  const searchKeywordUrl = searchParams.get("search") || "";
  const searchTypeUrl = searchParams.get("searchType") || "";
  // 검색 버튼 클릭시, input창 값 가져오기
  const [searchInput, setSearchInput] = useState<string>("");
  // select 창 값 가져오기
  const [searchType, setSearchType] = useState<string>("");

  // 상태 변환시 input, select창 초기화
  const inputRef = useRef<HTMLInputElement>(null);
  const selectRef = useRef<HTMLSelectElement>(null);

  const openModal = () => {
    setIsOpen(true);
  };

  // 검색, 초기화시 Pagination 컴포넌트 상태 초기화
  useEffect(() => {
    setCurrentPage(1);
    setStartPage(1);
    setActive("1");

    // 사이드바 클릭시 input & select 창 초기화 안되던 버그 핸들링 💡💡💡💡💡
    if (searchKeywordUrl === "" && searchTypeUrl === "all") {
      inputRef.current!.value = "";
      selectRef.current!.value = "all";
    }

    // 새로고침시 setSearchParams 로 할당해준 url 쿼리 스트링 초기화 안되던 버그 핸들링 🐛🐛🐛🐛🐛
    if (searchKeywordUrl !== "") {
      inputRef.current!.value = searchKeywordUrl;
      selectRef.current!.value = searchTypeUrl;
      setSearchInput(searchKeywordUrl);
      setSearchType(searchTypeUrl);
    }
  }, [searchParams, searchKeywordUrl, searchTypeUrl]);

  const { data: deviceServiceList, refetch } = useQuery(
    [
      "getDeviceServiceList",
      currentPage,
      size,
      searchTypeUrl,
      searchKeywordUrl,
    ],
    () =>
      apis.getDeviceServiceList(
        currentPage,
        size,
        searchTypeUrl,
        searchKeywordUrl
      ),
    {
      select: (data) => {
        total = data.data.total;
        return data.data.result;
      },
      onError: (error: AxiosError<AxiosCommonError>) => {
        handleError(error);
      },
    }
  );

  const renderAvailablePlans = (service: IDeviceService) => {
    const { subPlan, singlePlan } = service;

    const totalPlans = subPlan.length + singlePlan.length;

    if (totalPlans === 0) {
      return "-";
    }

    const primaryPlan =
      subPlan.length > 0 ? subPlan[0].title : singlePlan[0].title;
    const additionalPlans = totalPlans > 1 ? ` 외 ${totalPlans - 1}` : "";

    return primaryPlan + additionalPlans;
  };

  const options = [
    { value: "", label: "전체" },
    { value: "정기결제", label: "구독 결제" },
    { value: "단건결제", label: "단건 결제" },
  ];

  const onChangeSearchType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSearchType(e.target.value);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setSearchParams(
        `searchType=${searchType}&search=${e.currentTarget.value}`
      );
    }
  };

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
  };

  const onClickSearch = () => {
    setSearchParams(`searchType=${searchType}&search=${searchInput}`);
  };

  const onClickService = (service: IDeviceService) => {
    return () => {
      setEditItem({ ...service });
      setIsEdit(true);
      openModal();
    };
  };

  return (
    <div>
      <C.TitleBox>
        <div className="title">디바이스 서비스 정보 등록</div>
      </C.TitleBox>
      <C.ListBox>
        <C.ControlWrap>
          <Search
            total={total}
            options={options}
            selectRef={selectRef}
            inputRef={inputRef}
            onKeyDown={onKeyDown}
            onChangeSearchType={onChangeSearchType}
            onChangeInput={onChangeInput}
            onClickSearch={onClickSearch}
            placeholder="MAC, 고객 ID로 검색"
          />
          <RegBox>
            <button onClick={openModal}>신규 등록</button>
          </RegBox>
        </C.ControlWrap>
        <C.TableWrap>
          <TableUnit
            data={deviceServiceList}
            renderAvailablePlans={renderAvailablePlans}
            onClickService={onClickService}
          />
          {total > 0 ? (
            <Pagination
              total={total}
              size={10}
              setCurrentPage={setCurrentPage}
              startPage={startPage}
              setStartPage={setStartPage}
              active={active}
              setActive={setActive}
            />
          ) : null}
        </C.TableWrap>
      </C.ListBox>
      <DeviceServiceModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        editItem={editItem}
        setEditItem={setEditItem}
        refetch={refetch}
      />
    </div>
  );
};

export default ServiceManagement;

const RegBox = styled.div`
  button {
    width: 11rem;
    height: 4rem;
    /* background-color: gray;
    border-color: gray; */
  }
`;
