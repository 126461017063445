import React, { Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import { AiOutlineBars } from "react-icons/ai";
import { BiLogOut } from "react-icons/bi";
import { deleteCookie } from "../../../shared/cookie";
import apis from "../../../shared/apis";

const Header = (props: {
  sideBarClose: boolean;
  sideBarDisplay: string;
  setSideBarDisplay: Dispatch<SetStateAction<string>>;
}) => {
  // 로그아웃 api
  const logout = async () => {
    try {
      await apis.logout();
      deleteCookie("Authorization");
      deleteCookie("RefreshToken");
    } catch (e) {
      deleteCookie("Authorization");
      deleteCookie("RefreshToken");
      alert("알 수 없는 이유로 로그아웃에 실패했습니다.");
    }
  };

  return (
    <Wrap sideBarClose={props.sideBarClose}>
      <header>
        {/* 570px보다 작은 화면에서 햄버거바 생김 */}
        <AiOutlineBars
          onClick={() => {
            props.setSideBarDisplay("block");
          }}
        />
        <div className="header-left">통합관리자 페이지</div>
        <div className="header-right">
          <button
            onClick={() => {
              window.open("https://www.edgecross.ai/");
            }}
          >
            공식 홈페이지
          </button>
          <button
            className="logout"
            onClick={() => {
              logout();
            }}
          >
            로그아웃
          </button>
          <BiLogOut
            onClick={() => {
              logout();
            }}
          />
        </div>
      </header>
    </Wrap>
  );
};

export default Header;
const Wrap = styled.div`
  position: fixed;
  width: 100%;
  height: 5rem;
  background-color: ${(props) => props.theme.color.bg[200]};
  padding: 2rem;
  font-size: 1.6rem;
  border-bottom: 1px solid #e5e7eb;
  box-shadow: 2px 3px 5px 0px #e5e7eb;
  padding-left: ${(props: { sideBarClose: boolean }) =>
    props.sideBarClose ? "8rem" : "25rem"};
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
  @media (max-width: ${(props) => props.theme.breakpoints.Mobile}) {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
  header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    svg {
      font-size: 2rem;
      cursor: pointer;
      @media (min-width: ${(props) => props.theme.breakpoints.Mobile}) {
        display: none;
      }
    }
    .header-left {
      font-weight: 700;
    }
    .header-right {
      display: flex;
      gap: 10px;
      button {
        background-color: ${(props) => props.theme.color.red};
        border-color: ${(props) => props.theme.color.red};
        color: #fff;
        width: 10rem;
        font-weight: 400;
        @media (max-width: ${(props) => props.theme.breakpoints.Mobile}) {
          display: none;
        }
      }
      .logout {
        background-color: ${(props) => props.theme.color.gray};
      }
      svg {
        font-size: 2rem;
        cursor: pointer;
        @media (min-width: ${(props) => props.theme.breakpoints.Mobile}) {
          display: none;
        }
      }
    }
  }
`;
