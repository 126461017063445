import Table from "../../../ui/table/Table";
import { formatDate } from "../../../../shared/hook/date";
import { IDeviceService } from "../../../../pages/Management/ServiceManagement";
interface IPropsTableUnit {
  data: any;
  renderAvailablePlans: (service: IDeviceService) => string;
  onClickService: (service: IDeviceService) => () => void;
}

export default function TableUnit(props: IPropsTableUnit) {
  const { data, renderAvailablePlans, onClickService } = props;
  return (
    <Table>
      <Table.THead>
        <Table.TRow>
          <Table.TColumn.DefaultColumn size="lg">
            정보 등록일
          </Table.TColumn.DefaultColumn>
          <Table.TColumn.DefaultColumn size="sm">
            사용 고객 ID
          </Table.TColumn.DefaultColumn>
          <Table.TColumn.DefaultColumn size="sm">
            MAC
          </Table.TColumn.DefaultColumn>
          <Table.TColumn.DefaultColumn size="md">
            디바이스 종류
          </Table.TColumn.DefaultColumn>
          <Table.TColumn.DefaultColumn size="md">
            무료 사용 기간
          </Table.TColumn.DefaultColumn>
          <Table.TColumn.DefaultColumn size="md">
            현재 요금제
          </Table.TColumn.DefaultColumn>
          <Table.TColumn.DefaultColumn size="lg">
            서비스 시작일
          </Table.TColumn.DefaultColumn>
          <Table.TColumn.DefaultColumn size="lg">
            서비스 종료일
          </Table.TColumn.DefaultColumn>
          <Table.TColumn.DefaultColumn size="lg">
            선택 가능 요금제
          </Table.TColumn.DefaultColumn>
          <Table.TColumn.DefaultColumn size="sm">
            담당자
          </Table.TColumn.DefaultColumn>
        </Table.TRow>
      </Table.THead>
      <Table.TBody>
        {data?.map((v: IDeviceService) => {
          return (
            <Table.TRow
              key={v.id}
              onClick={onClickService(v)}
              hoverActive
              style={{ cursor: "pointer" }}
            >
              <Table.TCell.DefaultCell>
                {formatDate(v.regDate)}
              </Table.TCell.DefaultCell>
              <Table.TCell.DefaultCell>
                {v.deviceUsername || "-"}
              </Table.TCell.DefaultCell>
              <Table.TCell.DefaultCell>{v.mac || "-"}</Table.TCell.DefaultCell>
              <Table.TCell.DefaultCell>
                {v.deviceType || "-"}
              </Table.TCell.DefaultCell>
              <Table.TCell.DefaultCell>
                {v.freeTrialPeriod === 0
                  ? "없음"
                  : v.freeTrialPeriod === 1
                  ? "1개월"
                  : v.freeTrialPeriod === 12
                  ? "1년"
                  : "2년"}
              </Table.TCell.DefaultCell>
              <Table.TCell.DefaultCell>
                {v.curPlan
                  ? v.curPlan.title
                  : v.curPlanStartDate && v.curPlanEndDate
                  ? "무료 요금제"
                  : "-"}
              </Table.TCell.DefaultCell>
              <Table.TCell.DefaultCell>
                {v.curPlanStartDate ? formatDate(v.curPlanStartDate) : "-"}
              </Table.TCell.DefaultCell>
              <Table.TCell.DefaultCell>
                {v.curPlanEndDate ? formatDate(v.curPlanEndDate) : "-"}
              </Table.TCell.DefaultCell>
              <Table.TCell.DefaultCell>
                {renderAvailablePlans(v)}
              </Table.TCell.DefaultCell>
              <Table.TCell.DefaultCell>
                {v.regUsername || "-"}
              </Table.TCell.DefaultCell>
            </Table.TRow>
          );
        })}
      </Table.TBody>
    </Table>
  );
}
