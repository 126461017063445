import React from "react";
import Table from "../../../ui/table/Table";

interface IPropsTableUnit {
  data: any;
}
export default function TableUnit(props: IPropsTableUnit) {
  const { data } = props;
  return (
    <Table>
      <Table.THead>
        <Table.TRow>
          <Table.TColumn.DefaultColumn size="lg">
            아이디
          </Table.TColumn.DefaultColumn>
          <Table.TColumn.DefaultColumn size="lg">
            이름
          </Table.TColumn.DefaultColumn>
          <Table.TColumn.DefaultColumn size="lg">
            연락처
          </Table.TColumn.DefaultColumn>
          <Table.TColumn.DefaultColumn size="lg">
            주문 내역
          </Table.TColumn.DefaultColumn>
          <Table.TColumn.DefaultColumn size="lg">
            주문번호
          </Table.TColumn.DefaultColumn>
          <Table.TColumn.DefaultColumn size="lg">
            결제번호
          </Table.TColumn.DefaultColumn>
          <Table.TColumn.DefaultColumn size="lg">
            디바이스 번호
          </Table.TColumn.DefaultColumn>
          <Table.TColumn.DefaultColumn size="lg">
            사유
          </Table.TColumn.DefaultColumn>
          <Table.TColumn.DefaultColumn size="lg">
            승인시간
          </Table.TColumn.DefaultColumn>
        </Table.TRow>
      </Table.THead>
      <Table.TBody>
        {data?.data.result.map((v: any, i: number) => {
          return (
            <Table.TRow key={i}>
              <Table.TCell.DefaultCell>{v.buyer_email}</Table.TCell.DefaultCell>
              <Table.TCell.DefaultCell>{v.buyer_name}</Table.TCell.DefaultCell>
              <Table.TCell.DefaultCell>{v.buyer_tel}</Table.TCell.DefaultCell>
              <Table.TCell.DefaultCell>{v.name}</Table.TCell.DefaultCell>
              <Table.TCell.DefaultCell>
                {v.merchant_uid}
              </Table.TCell.DefaultCell>
              <Table.TCell.DefaultCell>{v.imp_uid}</Table.TCell.DefaultCell>
              <Table.TCell.DefaultCell>{v.deviceId}</Table.TCell.DefaultCell>
              <Table.TCell.DefaultCell>
                {v.cancel_reason}
              </Table.TCell.DefaultCell>
              <Table.TCell.DefaultCell>
                {v.canceled_at.split("T")[0] +
                  " " +
                  v.canceled_at.split("T")[1]}
                {/* {v.canceled_at.substr(2, 8) + " " + v.canceled_at.substr(11)} */}
              </Table.TCell.DefaultCell>
            </Table.TRow>
          );
        })}
      </Table.TBody>
    </Table>
  );
}
