import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { SubmitHandler, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { useMutation, useQuery } from "@tanstack/react-query";
import apis from "../../../shared/apis";
import { IDeviceService } from "../../../pages/Management/ServiceManagement";
import { AxiosError } from "axios";
import { AxiosCommonError } from "../../../shared/types";
import * as C from "../common/CommonStyles";

interface IPropsPlanRegModal {
  isOpen: boolean;
  isEdit: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  editItem?: IDeviceService | null;
  setEditItem?: React.Dispatch<React.SetStateAction<IDeviceService | null>>;
  refetch: () => void;
}

interface FormValue {
  mac: string;
  deviceType: string;
  freeTrialPeriod: string;
  subPlan: string;
  singlePlan: string;
}

interface IPlanType {
  id: string;
  title: string;
  alias: string;
  payType: string;
  payInterval: number;
  description: string;
}

const initValue = {
  mac: "",
  deviceType: "",
  freeTrialPeriod: "24",
  subPlan: "",
  singlePlan: "",
};

const DeviceServiceModal = (props: IPropsPlanRegModal) => {
  const {
    isOpen,
    setIsOpen,
    isEdit,
    setIsEdit,
    editItem,
    setEditItem,
    refetch,
  } = props;
  const [isMacValid, setIsMacValid] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm<FormValue>({
    mode: "onChange",
    defaultValues: initValue,
  });

  const mac = watch("mac");
  const subPlan = watch("subPlan");
  const singlePlan = watch("singlePlan");

  const closeModal = () => {
    Swal.fire({
      icon: "warning",
      title: "작성을 취소하시겠습니까?",
      text: "작성 중인 내용은 저장되지 않습니다.",
      showCancelButton: true,
      cancelButtonText: "취소",
      confirmButtonText: "확인",
    }).then((result) => {
      if (result.isConfirmed) {
        reset(initValue);
        setIsEdit(false);
        setIsOpen(false);
      }
    });
  };

  useEffect(() => {
    if (isEdit && editItem) {
      const editValue = {
        mac: editItem.mac || "",
        deviceType: editItem.deviceType || "",
        freeTrialPeriod: editItem.freeTrialPeriod.toString(),
        subPlan:
          editItem.subPlan.length > 0 ? editItem.subPlan[0].id.toString() : "",
        singlePlan:
          editItem.singlePlan.length > 0
            ? editItem.singlePlan[0].id.toString()
            : "",
      };
      reset(editValue);
    }
  }, [editItem, isEdit, reset]);

  // MAC 주소 유효성 체크
  const { refetch: checkMac } = useQuery(
    ["validMac"],
    () => apis.checkValidMac(mac),
    {
      enabled: false,
      retry: 0,
      suspense: false,
      onSuccess: (data) => {
        Swal.fire({
          icon: "success",
          title: "유효한 MAC 주소입니다.",
        });
        setIsMacValid(true);
        setValue("deviceType", data.data.result[0].deviceType);
      },
      onError: (error: AxiosError<AxiosCommonError>) => {
        if (error.response?.data.errorMessage === "Device already registered") {
          Swal.fire({
            icon: "error",
            title: "이미 등록된 디바이스입니다.",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "유효하지 않은 MAC 주소입니다.",
          });
        }
        setIsMacValid(false);
      },
    }
  );

  // 선택가능한 요금제 조회
  const { data: allPlans } = useQuery(["getAllPlans"], apis.getAllPlans, {
    select: (data) => data.data.result[0],
  });

  // 디바이스 서비스 정보 등록
  const { mutate: postDeviceService } = useMutation(
    (data: { mac: string; freeTrialPeriod: number; planId: number[] }) =>
      apis.postDeviceService(data),
    {
      onSuccess: () => {
        Swal.fire({
          icon: "success",
          title: "디바이스 서비스 정보가 등록되었습니다.",
        }).then(() => {
          reset(initValue);
          setIsOpen(false);
          refetch();
        });
      },
    }
  );

  // 디바이스 서비스 정보 수정
  const { mutate: editDeviceService } = useMutation(
    (data: {
      id: string;
      data: { freeTrialPeriod: number; planId: number[] };
    }) => apis.editDeviceService(data),
    {
      onSuccess: () => {
        console.log("edit success");
        Swal.fire({
          icon: "success",
          title: "디바이스 서비스 정보가 수정되었습니다.",
        }).then(() => {
          if (setEditItem) {
            reset(initValue);
            setEditItem(null);
            setIsOpen(false);
            refetch();
          }
        });
      },
    }
  );

  // 맥 주소 유효성 체크
  const onClickValidateMac = () => {
    checkMac();
  };

  // 요금제 셀렉트 유효성 체크
  const validateSelect = () => {
    if (subPlan === "" && singlePlan === "") {
      return false;
    }
    return true;
  };

  // 데이터 형식 변환
  const formatData = (data: FormValue) => {
    const freeTrialPeriod = parseInt(data.freeTrialPeriod);
    const subPlanIdNum = parseInt(data.subPlan);
    const singlePlanIdNum = parseInt(data.singlePlan);
    const planId = [subPlanIdNum, singlePlanIdNum].filter((id) => !isNaN(id));
    return { freeTrialPeriod, planId };
  };

  // 등록/수정 요청
  const onSubmit: SubmitHandler<FormValue> = (data) => {
    const validPlans = validateSelect();

    if (!validPlans) {
      Swal.fire({
        icon: "error",
        title: "요금제를 1개 이상 선택해주세요.",
      });
      return;
    }

    if (!isMacValid && !isEdit) {
      Swal.fire({
        icon: "error",
        title: "MAC 주소를 확인하세요.",
      });
      return;
    }

    Swal.fire({
      icon: "question",
      title: `디바이스 서비스 정보를  ${isEdit ? "수정" : "등록"}하시겠습니까?`,
      showCancelButton: true,
      confirmButtonText: isEdit ? "수정" : "등록",
      cancelButtonText: "취소",
    }).then((result) => {
      if (result.isConfirmed) {
        if (isEdit) {
          const { freeTrialPeriod, planId } = formatData(data);
          const sendData = {
            id: editItem?.id as string,
            data: {
              freeTrialPeriod: freeTrialPeriod,
              planId: planId,
            },
          };
          editDeviceService(sendData);
          return;
        }

        const { freeTrialPeriod, planId } = formatData(data);
        const sendData = {
          mac: data.mac,
          freeTrialPeriod: freeTrialPeriod,
          planId: planId,
        };
        postDeviceService(sendData);
      }
    });
  };

  return (
    <React.Fragment>
      {isOpen ? (
        <C.ModalWrap>
          <C.Modal>
            <ModalContent>
              <Title>디바이스 서비스 정보 등록</Title>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <label className="label" htmlFor="mac">
                    <RequiredField>*</RequiredField>
                    MAC
                  </label>
                  <MacInputBox>
                    <input
                      id="mac"
                      {...register("mac", {
                        required: "MAC 주소를 입력해주세요.",
                      })}
                      disabled={isEdit}
                    />
                    <button
                      type="button"
                      onClick={onClickValidateMac}
                      disabled={isEdit}
                    >
                      확인
                    </button>
                  </MacInputBox>
                  {errors.mac && (
                    <Error>{errors.mac.message?.toString()}</Error>
                  )}
                </div>
                <div>
                  <label className="label" htmlFor="deviceType">
                    <RequiredField>*</RequiredField>
                    디바이스 종류
                  </label>
                  <input
                    id="deviceType"
                    {...register("deviceType", {
                      // required: "등록되지 않은 디바이스입니다.",
                    })}
                    disabled
                    // readOnly
                  />
                  {errors.deviceType && (
                    <Error>{errors.deviceType.message?.toString()}</Error>
                  )}
                </div>
                <div>
                  <div className="label">
                    <RequiredField>*</RequiredField>
                    최초 등록 후 무료 이용 기간
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="m24"
                      value="24"
                      {...register("freeTrialPeriod", {
                        required: "무료 이용 기간을 선택해주세요.",
                      })}
                    />
                    <RadioLabel id="m24">2년</RadioLabel>
                    <input
                      type="radio"
                      id="m12"
                      value="12"
                      {...register("freeTrialPeriod", {
                        required: "무료 이용 기간을 선택해주세요.",
                      })}
                    />
                    <RadioLabel id="m12">1년</RadioLabel>
                    <input
                      type="radio"
                      id="m1"
                      value="1"
                      {...register("freeTrialPeriod", {
                        required: "무료 이용 기간을 선택해주세요.",
                      })}
                    />
                    <RadioLabel id="m1">1개월</RadioLabel>
                    <input
                      type="radio"
                      id="0"
                      value="0"
                      {...register("freeTrialPeriod", {
                        required: "무료 이용 기간을 선택해주세요.",
                      })}
                    />
                    <RadioLabel id="none">없음</RadioLabel>
                  </div>
                </div>
                <div>
                  <div>
                    <div className="label">
                      <RequiredField>*</RequiredField>
                      선택 가능 요금제 (1개 이상 필수 선택)
                    </div>
                    <SelectBox>
                      <div className="label select">구독 결제 요금제</div>
                      <Select {...register("subPlan")}>
                        <option value="">요금제 옵션을 선택해주세요.</option>
                        {allPlans.subPlan.map((plan: IPlanType) => (
                          <option value={plan.id} key={plan.id}>
                            {plan.title}
                          </option>
                        ))}
                      </Select>
                    </SelectBox>
                    <SelectBox>
                      <div className="label select">단건 결제 요금제</div>
                      <Select {...register("singlePlan")}>
                        <option value="">요금제 옵션을 선택해주세요.</option>
                        {allPlans.singlePlan.map((plan: IPlanType) => (
                          <option value={plan.id} key={plan.id}>
                            {plan.title}
                          </option>
                        ))}
                      </Select>
                    </SelectBox>
                    {errors.subPlan && (
                      <Error>{errors.subPlan.message?.toString()}</Error>
                    )}
                  </div>
                </div>
                <ButtonBox>
                  <CancelButton type="button" onClick={closeModal}>
                    취소
                  </CancelButton>
                  <SubmitButton type="submit">
                    {isEdit ? "수정" : "등록"}
                  </SubmitButton>
                </ButtonBox>
              </Form>
            </ModalContent>
          </C.Modal>
        </C.ModalWrap>
      ) : null}
    </React.Fragment>
  );
};

export default DeviceServiceModal;

const ModalContent = styled(C.ModalContent)`
  padding: 16px 62px;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 10px;
  width: 100%;
  text-align: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;

  > div:not(:last-child) {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }

  input,
  select {
    padding: 8px;
    border: ${(props) => props.theme.border.gray1};
  }

  .label {
    margin-bottom: 8px;
    font-weight: 600;
  }

  .select {
    color: ${(props) => props.theme.color.gray};
    font-weight: 400;
    font-size: 1.3rem;
  }
`;

const RequiredField = styled.span`
  color: ${(props) => props.theme.color.error};
  margin-right: 4px;
`;

const ButtonBox = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  width: 100%;
  margin-bottom: 20px;
`;

const CancelButton = styled.button`
  background-color: ${(props) => props.theme.color.gray};
  border-color: ${(props) => props.theme.color.gray};
  width: 30%;
`;

const SubmitButton = styled.button`
  background-color: ${(props) => props.theme.color.red};
  border-color: ${(props) => props.theme.color.Red};
  width: 30%;
`;

const RadioLabel = styled.label`
  margin-left: 6px;
  margin-right: 20px;
`;

const SelectBox = styled.div`
  margin-bottom: 10px;
`;

const Select = styled.select`
  width: 100%;
`;

const MacInputBox = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;

  > input {
    flex: 1;
  }

  button:disabled {
    background-color: ${(props) => props.theme.color.gray};
  }
`;

const Error = styled.p`
  color: ${(props) => props.theme.color.error};
  font-size: 12px;
  margin-top: 4px;
`;
