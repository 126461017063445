import React from "react";
import { GlobalStyles } from "./shared/styles/GlobalStyles";
import { ThemeProvider } from "styled-components";
import { Routes, Route, Navigate } from "react-router-dom";
import Theme from "./shared/styles/Theme";

// 페이지 import
import Layout from "./components/ui/layout/Layout";
import MacAddress from "./pages/Management/MacAddress";
import ScautrCompany from "./pages/Scautr/Scautr";
import Login from "./pages/Login/Login";
import Signup from "./pages/Signup/SignUp";
import NotFound from "./components/ui/err/NotFound";
import ErrorBoundary from "./components/ui/err/ErrorBoundary";
import DataPoint from "./pages/DataPoint/DataPoint";
import DataPointDetail from "./pages/DataPoint/DataPointDetail";
import DataPointPayment from "./pages/DataPoint/DataPointPayment";
import DataPointRefund from "./pages/DataPoint/DataPointRefund";
import DataPointRefundSuccess from "./pages/DataPoint/DataPointRefundSuccess";
import Progix from "./pages/Progix/Progix";
import ProgixPayment from "./pages/Progix/ProgixPayment";
import ProgixRefund from "./pages/Progix/ProgixRefund";
import ProgixRefundSuccess from "./pages/Progix/ProgixRefundSuccess";

// 토큰 payload에 담겨오는 정보를 바탕으로 로그인 라우팅 처리
import jwtDecode from "jwt-decode";
import { getCookie } from "./shared/cookie";
import PlanManagement from "./pages/Management/PlanManagement";
import ServiceManagement from "./pages/Management/ServiceManagement";

function App() {
  // 토큰 타입정의
  interface TokenProps {
    exp: number;
    iat: number;
    roles: string[];
    sub: string;
  }

  const accessToken = getCookie("Authorization");
  let authority: TokenProps;
  let isAuth: boolean | undefined;

  if (accessToken) {
    authority = jwtDecode(accessToken);
    isAuth = authority.roles[0] === "ROLE_ADMIN";
  }

  return (
    <React.Fragment>
      <ThemeProvider theme={Theme}>
        <GlobalStyles />
        <ErrorBoundary>
          <Routes>
            <Route
              path="/"
              element={
                isAuth ? <Navigate to="/admin/management/modlink" /> : <Login />
              }
            />
            <Route
              path="/signup"
              element={
                isAuth ? (
                  <Navigate to="/admin/management/modlink" />
                ) : (
                  <Signup />
                )
              }
            />
            <Route
              path="/admin"
              element={isAuth ? <Layout /> : <Navigate to="/" />}
            >
              {/* <Route path="/admin/modlink" element={<MacAddress />} /> */}
              <Route path="/admin/scautrCompany" element={<ScautrCompany />} />
              <Route path="/admin/progix/member" element={<Progix />} />
              <Route path="/admin/progix/payment" element={<ProgixPayment />} />
              <Route path="/admin/progix/refund" element={<ProgixRefund />} />
              <Route
                path="/admin/progix/refund-success"
                element={<ProgixRefundSuccess />}
              />
              <Route path="/admin/datapoint/member" element={<DataPoint />} />
              <Route
                path="/admin/datapoint/:userId"
                element={<DataPointDetail />}
              />
              <Route
                path="/admin/datapoint/payment"
                element={<DataPointPayment />}
              />
              <Route
                path="/admin/datapoint/refund"
                element={<DataPointRefund />}
              />
              <Route
                path="/admin/datapoint/refund-success"
                element={<DataPointRefundSuccess />}
              />
              <Route
                path="/admin/management/modlink"
                element={<MacAddress />}
              />
              <Route
                path="/admin/management/plan"
                element={<PlanManagement />}
              />
              <Route
                path="/admin/management/deviceService"
                element={<ServiceManagement />}
              />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </ErrorBoundary>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
