import styled from "styled-components";

export const TitleBox = styled.div`
  border: ${(props) => props.theme.border.gray2};
  padding: 1rem;
  .title {
    font-weight: 700;
    font-size: 1.8rem;
    margin-bottom: 1rem;
    padding: 1rem;
    border: ${(props) => props.theme.border.gray1};
    text-align: center;
  }
`;

export const ListBox = styled.div`
  border: ${(props) => props.theme.border.gray2};
  padding: 1rem;
  margin: 2rem 0;
  overflow-x: auto;
  .table-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    @media (max-width: 860px) {
      display: none;
    }
    button {
      width: 10rem;
      height: 4rem;
      background-color: ${(props) => props.theme.color.red};
      border-color: ${(props) => props.theme.color.red};
    }
    .table-top-left {
      display: flex;
      align-items: center;
      gap: 1rem;
      .total-cnt {
        font-size: 1.6rem;
        font-weight: 700;
      }
      select {
        border: ${(props) => props.theme.border.gray2};
        width: 12rem;
        padding: 1rem;
        height: 4rem;
      }
      input {
        border: ${(props) => props.theme.border.gray1};
        outline: none;
        padding: 1rem;
        width: 20rem;

        height: 4rem;
        &:focus {
          border: ${(props) => props.theme.border.solid3};
          box-shadow: 0 0 0 0.2rem ${(props) => props.theme.color.shadow.blue};
          outline: none;
        }
        &:focus::placeholder {
          color: transparent;
        }
      }
    }
  }
`;

export const TableWrap = styled.div`
  width: 100%;
  overflow-y: auto;
`;

export const SearchBox = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  select {
    border: ${(props) => props.theme.border.gray2};
    width: 12rem;
    padding: 1rem;
    height: 4rem;
  }
  input {
    border: ${(props) => props.theme.border.gray1};
    outline: none;
    padding: 1rem;
    width: 20rem;

    height: 4rem;
    &:focus {
      border: ${(props) => props.theme.border.solid3};
      box-shadow: 0 0 0 0.2rem ${(props) => props.theme.color.shadow.blue};
      outline: none;
    }
    &:focus::placeholder {
      color: transparent;
    }
  }
`;

export const TotalCount = styled.div`
  font-size: 1.6rem;
  font-weight: 700;
`;

export const ControlWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  @media (max-width: 860px) {
    display: none;
  }
`;

export const ModalWrap = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  animation: modal-bg-show 0.3s;

  @keyframes modal-bg-show {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const Modal = styled.div`
  width: 450px;
  height: max-content;
  background-color: ${(props) => props.theme.color.white};
  @media (max-width: 600px) {
    width: 360px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
