import React from "react";

interface IPropsTBody extends React.HTMLAttributes<HTMLTableSectionElement> {
  children: React.ReactNode;
}

function TBody({ children, ...props }: IPropsTBody) {
  return <tbody {...props}>{children}</tbody>;
}

export default TBody;
