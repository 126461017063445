import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { SubmitHandler, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { useMutation } from "@tanstack/react-query";
import apis from "../../../shared/apis";
import { IPayPlan } from "../../../pages/Management/PlanManagement";
import * as C from "../common/CommonStyles";

interface IPropsPlanRegModal {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isEdit: boolean;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  editItem?: IPayPlan | null;
  setEditItem?: React.Dispatch<React.SetStateAction<IPayPlan | null>>;
  refetch: () => void;
}

export interface FormValue {
  title: string;
  alias: string;
  payType: string;
  payInterval: string;
  orgPrice: string;
  price: string;
  description: string;
}

const initValue = {
  title: "",
  alias: "",
  payInterval: "1",
  payType: "subPlan",
  orgPrice: "",
  price: "",
  description: "",
};

const PlanRegModal = (props: IPropsPlanRegModal) => {
  const {
    isOpen,
    setIsOpen,
    isEdit,
    setIsEdit,
    editItem,
    setEditItem,
    refetch,
  } = props;

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm<FormValue>({
    mode: "onChange",
    defaultValues: initValue,
  });

  useEffect(() => {
    if (isEdit && editItem) {
      const editValue = {
        title: editItem.title || "",
        alias: editItem.alias || "",
        payInterval: editItem.payInterval === 1 ? "1" : "12",
        payType: editItem.payType === "정기결제" ? "subPlan" : "singlePlan",
        orgPrice: editItem.orgPrice?.toString() || "",
        price: editItem.price?.toString() || "",
        description: editItem.description || "",
      };
      reset(editValue);
    }
  }, [isEdit, editItem, reset]);

  const selectedPayType = watch("payType");

  const onPayTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedPayType = e.target.value;
    setValue("payType", selectedPayType);
    if (selectedPayType === "subPlan") {
      setValue("payInterval", "1");
    } else if (selectedPayType === "singlePlan") {
      setValue("payInterval", "12");
    }
  };

  const closeModal = () => {
    Swal.fire({
      icon: "warning",
      title: "작성을 취소하시겠습니까?",
      text: "작성 중인 내용은 저장되지 않습니다.",
      showCancelButton: true,
      cancelButtonText: "취소",
      confirmButtonText: "확인",
    }).then((result) => {
      if (result.isConfirmed) {
        reset(initValue);
        setIsEdit(false);
        setIsOpen(false);
      }
    });
  };

  const { mutate: postPlan } = useMutation((data: any) => apis.postPlan(data), {
    onSuccess: () => {
      Swal.fire({
        icon: "success",
        title: "성공적으로 등록되었습니다.",
      }).then(() => {
        reset(initValue);
        setIsOpen(false);
        refetch();
      });
    },
  });

  const { mutate: editPlan } = useMutation(
    (editData: { id: string; data: { description: string } }) =>
      apis.editPlan(editData),
    {
      onSuccess: () => {
        Swal.fire({
          icon: "success",
          title: "성공적으로 수정되었습니다.",
        }).then(() => {
          if (setEditItem) {
            reset(initValue);
            setEditItem(null);
            setIsOpen(false);
            refetch();
          }
        });
      },
    }
  );

  // 정상가와 판매가 비교
  const validatePrice = (orgPrice: string, price: string) => {
    const orgPriceNum = Number(orgPrice.replace(/,/g, ""));
    const priceNum = Number(price.replace(/,/g, ""));

    if (orgPriceNum < priceNum) {
      return false;
    }
    return true;
  };

  // 요금제 생성 페이로드 생성
  const createPostData = (data: FormValue) => {
    return {
      title: data.title,
      alias: data.alias,
      payType: data.payType === "subPlan" ? "정기결제" : "단건결제",
      payInterval: parseInt(data.payInterval),
      orgPrice: parseInt(data.orgPrice),
      price: parseInt(data.price),
      description: data.description,
    };
  };

  // 요금제 수정 페이로드 생성
  const createEditData = (data: FormValue) => {
    if (editItem && editItem.id) {
      return {
        id: editItem?.id,
        data: { description: data.description },
      };
    }
    return null;
  };

  const onSubmit: SubmitHandler<FormValue> = (data) => {
    const isValid = validatePrice(data.orgPrice, data.price);
    if (!isValid) {
      Swal.fire({
        icon: "error",
        title: "판매가는 정상가를 초과할 수 없습니다.",
      });
      return;
    }

    Swal.fire({
      icon: "question",
      title: `요금제를 ${isEdit ? "수정" : "등록"}하시겠습니까?`,
      showCancelButton: true,
      confirmButtonText: isEdit ? "수정" : "등록",
      cancelButtonText: "취소",
    }).then((result) => {
      if (result.isConfirmed) {
        if (isEdit) {
          const sendData = createEditData(data);
          sendData && editPlan(sendData);
          return;
        }
        const sendData = createPostData(data);
        postPlan(sendData);
      }
    });
  };

  return (
    <React.Fragment>
      {isOpen ? (
        <C.ModalWrap>
          <C.Modal>
            <ModalContent>
              <Title>신규 요금제 생성</Title>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <label className="label" htmlFor="title">
                    <RequiredField>*</RequiredField>
                    요금제명
                  </label>
                  <input
                    id="title"
                    placeholder="관리를 위한 요금제명입니다."
                    {...register("title", {
                      required: "요금제명을 입력해주세요.",
                      maxLength: {
                        value: 20,
                        message: "최대 20자까지 입력 가능합니다.",
                      },
                    })}
                    disabled={isEdit}
                  />
                  {errors.title && (
                    <Error>{errors.title.message?.toString()}</Error>
                  )}
                </div>
                <div>
                  <label className="label" htmlFor="alias">
                    <RequiredField>*</RequiredField>
                    화면 표시용 요금제명
                  </label>
                  <input
                    id="alias"
                    placeholder="사용자 화면에 표시되는 요금제명입니다."
                    {...register("alias", {
                      required: "화면 표시용 요금제명을 입력해주세요.",
                      maxLength: {
                        value: 20,
                        message: "최대 20자까지 입력 가능합니다.",
                      },
                    })}
                    disabled={isEdit}
                  />
                  {errors.alias && (
                    <Error>{errors.alias.message?.toString()}</Error>
                  )}
                </div>
                <div>
                  <div className="label">
                    <RequiredField>*</RequiredField>
                    결제 타입
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="subPlan"
                      value="subPlan"
                      {...register("payType")}
                      checked={selectedPayType === "subPlan"}
                      onChange={onPayTypeChange}
                      disabled={isEdit}
                    />
                    <RadioLabel htmlFor="subPlan">구독 결제</RadioLabel>
                    <input
                      type="radio"
                      id="singlePlan"
                      value="singlePlan"
                      {...register("payType")}
                      checked={selectedPayType === "singlePlan"}
                      onChange={onPayTypeChange}
                      disabled={isEdit}
                    />
                    <RadioLabel htmlFor="singlePlan">단건 결제</RadioLabel>
                  </div>
                </div>
                <div>
                  <div className="label">
                    <RequiredField>*</RequiredField>
                    결제 주기
                  </div>
                  <div>
                    {selectedPayType === "subPlan" && (
                      <React.Fragment>
                        <input
                          type="radio"
                          id="m1"
                          value="1"
                          {...register("payInterval")}
                          onChange={() => setValue("payInterval", "1")}
                          disabled={isEdit}
                        />
                        <RadioLabel htmlFor="m1">1개월</RadioLabel>
                      </React.Fragment>
                    )}
                    {selectedPayType === "singlePlan" && (
                      <React.Fragment>
                        <input
                          type="radio"
                          id="m12"
                          value="12"
                          {...register("payInterval")}
                          onChange={() => setValue("payInterval", "12")}
                          disabled={isEdit}
                        />
                        <RadioLabel htmlFor="m12">1년</RadioLabel>
                      </React.Fragment>
                    )}
                  </div>
                </div>
                <div>
                  <label className="label" htmlFor="orgPrice">
                    <RequiredField>*</RequiredField>
                    정상가
                  </label>
                  <input
                    type="text"
                    id="orgPrice"
                    placeholder="할인 전 금액입니다."
                    {...register("orgPrice", {
                      required: "정상가를 입력해주세요.",
                      pattern: {
                        value: /^[0-9,]*$/,
                        message: "숫자만 입력 가능합니다.",
                      },
                    })}
                    disabled={isEdit}
                  />
                  {errors.orgPrice && (
                    <Error>{errors.orgPrice.message?.toString()}</Error>
                  )}
                </div>
                <div>
                  <label className="label" htmlFor="price">
                    <RequiredField>*</RequiredField>
                    판매가(최종 표시금액)
                  </label>
                  <input
                    type="text"
                    id="price"
                    placeholder="고객이 결제하는 금액입니다."
                    {...register("price", {
                      required: "판매가를 입력해주세요.",
                      pattern: {
                        value: /^[0-9,]*$/,
                        message: "숫자만 입력 가능합니다.",
                      },
                    })}
                    disabled={isEdit}
                  />
                  {errors.price && (
                    <Error>{errors.price.message?.toString()}</Error>
                  )}
                </div>
                <div>
                  <label className="label" htmlFor="description">
                    메모
                  </label>
                  <textarea
                    id="description"
                    placeholder="요금제 관련 특이사항 등 입력"
                    {...register("description", {
                      maxLength: {
                        value: 200,
                        message: "최대 200자까지 입력 가능합니다.",
                      },
                    })}
                  />
                  {errors.description && (
                    <Error>{errors.description.message?.toString()}</Error>
                  )}
                </div>
                <ButtonBox>
                  <CancelButton type="button" onClick={closeModal}>
                    취소
                  </CancelButton>
                  <SubmitButton type="submit">
                    {isEdit ? "수정" : "등록"}
                  </SubmitButton>
                </ButtonBox>
              </Form>
            </ModalContent>
          </C.Modal>
        </C.ModalWrap>
      ) : null}
    </React.Fragment>
  );
};

export default PlanRegModal;

const ModalContent = styled(C.ModalContent)`
  padding: 16px 62px;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 10px;
  width: 100%;
  text-align: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;

  > div:not(:last-child) {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }

  input,
  textarea {
    padding: 8px;
    border: ${(props) => props.theme.border.gray1};
  }

  textarea {
    resize: none;
    height: 100px;
  }

  .label {
    margin-bottom: 8px;
    font-weight: 600;
  }
`;

const RadioLabel = styled.label`
  margin-left: 6px;
  margin-right: 20px;
`;

const RequiredField = styled.span`
  color: ${(props) => props.theme.color.red};
  margin-right: 4px;
`;

const ButtonBox = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  width: 100%;
  margin-bottom: 20px;
`;

const CancelButton = styled.button`
  background-color: ${(props) => props.theme.color.gray};
  border-color: ${(props) => props.theme.color.gray};
  width: 30%;
`;

const SubmitButton = styled.button`
  background-color: ${(props) => props.theme.color.red};
  border-color: ${(props) => props.theme.color.red};
  width: 30%;
`;

const Error = styled.p`
  color: ${(props) => props.theme.color.red};
  font-size: 12px;
  margin-top: 4px;
`;
