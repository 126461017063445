import React from "react";
import { Tr } from "./styles";

interface IPropsTRow extends React.HTMLAttributes<HTMLTableRowElement> {
  children: React.ReactNode;
  hoverActive?: boolean;
}

function TRow({ children, hoverActive = false, ...props }: IPropsTRow) {
  return (
    <Tr hoverActive={hoverActive} {...props}>
      {children}
    </Tr>
  );
}

export default TRow;
