import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import apis from "../../../../shared/apis";
import { AxiosError } from "axios";
import { AxiosCommonError } from "../../../../shared/types";
import { handleError } from "../../../ui/err/handleError";
import Swal from "sweetalert2";
import styled from "styled-components";
import Search from "../../../ui/search/Search";
import * as C from "../../../ui/common/CommonStyles";

interface IPropsSearchUnit {
  searchKeywordUrl: string;
  searchTypeUrl: string;
  total: number;
  openModal: () => void;
  initPagination: () => void;
  resetCheckBoxArr: () => void;
  checkBoxArr: number[];
  handleSearchParams: (searchParams: string) => void;
}

export default function SearchUnit(props: IPropsSearchUnit) {
  const {
    searchKeywordUrl,
    searchTypeUrl,
    total,
    openModal,
    initPagination,
    resetCheckBoxArr,
    checkBoxArr,
    handleSearchParams,
  } = props;

  // 검색 버튼 클릭시, input창 값 가져오기
  const [searchInput, setSearchInput] = useState<string>("");
  // select 창 값 가져오기
  const [searchType, setSearchType] = useState<string>("all");

  // 상태 변환시 input, select창 초기화
  const inputRef = useRef<HTMLInputElement>(null);
  const selectRef = useRef<HTMLSelectElement>(null);

  // 검색, 초기화시 Pagination 컴포넌트 상태 초기화
  useEffect(() => {
    initPagination();

    // 사이드바 클릭시 input & select 창 초기화 안되던 버그 핸들링 💡💡💡💡💡
    if (searchKeywordUrl === "" && searchTypeUrl === "all") {
      inputRef.current!.value = "";
      selectRef.current!.value = "all";
    }

    // 새로고침시 setSearchParams 로 할당해준 url 쿼리 스트링 초기화 안되던 버그 핸들링 🐛🐛🐛🐛🐛
    if (searchKeywordUrl !== "") {
      inputRef.current!.value = searchKeywordUrl;
      selectRef.current!.value = searchTypeUrl;
      setSearchInput(searchKeywordUrl);
      setSearchType(searchTypeUrl);
    }
  }, [searchKeywordUrl, searchTypeUrl]);

  const queryClient = useQueryClient();

  // 선택 삭제 mutate
  const { mutate: deleteModLinkMutate } = useMutation(
    (data: { id: number[] }) => apis.deleteModLink(data),
    {
      onSuccess: () => {
        Swal.fire({
          icon: "success",
          title: "삭제가 완료되었습니다.",
        });
        resetCheckBoxArr();
        queryClient.invalidateQueries({ queryKey: ["loadModLinkQuery"] });
      },
      onError: (error: AxiosError<AxiosCommonError>) => {
        handleError(error);
      },
    }
  );

  // 선택 삭제 재확인 confirm
  const deleteModLink = () => {
    Swal.fire({
      icon: "question",
      title: "정말 삭제하시겠습니까?",
      showCancelButton: true,
      confirmButtonText: "삭제",
      cancelButtonText: "취소",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteModLinkMutate({ id: checkBoxArr });
      }
    });
  };

  const options = [
    { value: "all", label: "All" },
    { value: "serialNumber", label: "S/N" },
    { value: "macAddress", label: "MAC" },
    { value: "company", label: "출고 기업" },
    { value: "type", label: "모드링크 종류" },
  ];

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSearchParams(`searchType=${searchType}&search=${searchInput}`);
      resetCheckBoxArr();
    }
  };

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
  };

  const onClickSearch = () => {
    handleSearchParams(`searchType=${searchType}&search=${searchInput}`);
    resetCheckBoxArr();
  };

  const onChangeSearchType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSearchType(e.target.value);
  };

  return (
    <C.ControlWrap>
      <Search
        options={options}
        total={total}
        onKeyDown={onKeyDown}
        onChangeInput={onChangeInput}
        onClickSearch={onClickSearch}
        onChangeSearchType={onChangeSearchType}
        selectRef={selectRef}
        inputRef={inputRef}
      />
      <DownloadBox>
        <button className="excel-upload" onClick={openModal}>
          엑셀로 추가하기
        </button>
        <button onClick={deleteModLink}>선택삭제</button>
      </DownloadBox>
    </C.ControlWrap>
  );
}

const DownloadBox = styled.div`
  display: flex;
  gap: 10px;
  .excel-upload {
    background-color: ${(props) => props.theme.color.green};
    width: 110px;
  }
  button {
    background-color: ${(props) => props.theme.color.gray};
  }
`;
