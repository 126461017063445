import styled from "styled-components";
import * as C from "../common/CommonStyles";

export default function Search(props: any) {
  const {
    total,
    options,
    onChangeSearchType,
    selectRef,
    onKeyDown,
    onChangeInput,
    inputRef,
    onClickSearch,
    placeholder,
  } = props;
  return (
    <C.SearchBox>
      <select onChange={onChangeSearchType} ref={selectRef}>
        {options.map((option: any) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <input
        type="text"
        placeholder={placeholder || "검색"}
        onKeyDown={onKeyDown}
        onChange={onChangeInput}
        ref={inputRef}
      />
      <SearchBtn onClick={onClickSearch}>검색</SearchBtn>
      <C.TotalCount>
        (전체 <span>{total}</span>개)
      </C.TotalCount>
    </C.SearchBox>
  );
}

const SearchBtn = styled.button`
  width: 10rem;
  height: 4rem;
  background-color: ${(props) => props.theme.color.red};
  border-color: ${(props) => props.theme.color.red};
`;
