import React from "react";

interface IPropsTHead extends React.HTMLAttributes<HTMLTableSectionElement> {
  children: React.ReactNode;
}

function THead({ children, ...props }: IPropsTHead) {
  return <thead {...props}>{children}</thead>;
}

export default THead;
