import axios from "axios";
import { getCookie, setAccessToken, setRefreshToken } from "./cookie";
import mem from "mem";
import { FormValue } from "../components/ui/modal/PlanRegModal";

const api = axios.create({
  // axios 버전이 바뀌면서 기존 문법이 안먹히던 이슈 발생
  // headers의 Content-Type와 Accpet 설정 & config.headers 설정
  // baseURL: "http://localhost:8080/api",
  // baseURL: "http://13.125.160.114/api",
  // baseURL: "https://cloud-ih.shop/api",
  // baseURL: "https://admin.edgecross.ai/api",
  // baseURL: "https://71a7-112-171-127-186.ngrok-free.app/api",
  baseURL: `${process.env.REACT_APP_BACKEND_ADDRESS}`,

  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    "ngrok-skip-browser-warning": `${process.env.REACT_APP_NGROK_SKIP_BROWSER_WARNING}`,
    Accept: "application/json,",
  },
});

api.interceptors.request.use((config: any) => {
  const accessToken = getCookie("Authorization");
  config.headers.Authorization = `${accessToken}`;
  return config;
});

const getReissueToken = mem(
  async (): Promise<string | void> => {
    const AccessToken = getCookie("Authorization");
    const RefreshToken = getCookie("RefreshToken");

    try {
      const reissue = await axios({
        url: `${process.env.REACT_APP_BACKEND_ADDRESS}/reissue`,
        method: "get",
        headers: {
          Authorization: `${AccessToken}`,
          RefreshToken: `${RefreshToken}`,
        },
      });
      if (reissue) {
        setAccessToken(reissue.data.accessToken);
        setRefreshToken(reissue.data.refreshToken);
      }
      return reissue.data;
    } catch (error) {}
  },
  { maxAge: 1000 }
);

api.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const {
      config,
      response: { data },
    } = err;
    if (data.errorMessage === "ACCESSTOKEN_EXPRIED_ERR") {
      config.sent = true;
      const Token: any = await getReissueToken();
      if (Token) {
        config.headers = {
          ...config.headers,
          Authorization: `${Token.accessToken}`,
          RefreshToken: `${Token.refreshToken}`,
          accept: "application/json, text/plain, */*",
          "content-type": "application/json",
        };
        return await api.request(config);
      }
    }
    return Promise.reject(err);
  }
);

const apis = {
  // LOGIN
  login: (data: { email: string; password: string }) =>
    api.post("/login", data),
  signUp: (data: { name: string; email: string; password: string }) =>
    api.post("/signup", data),
  logout: () => api.get("/logout"),

  // MAIN
  RegistrationModLink: (data: {
    deviceType: string;
    serialNumber: string;
    macAddress: string;
    company: string;
    stage: number;
  }) => api.post("/modlink", data),
  getModLink: (currentPage: number, searchType: string, keyword: string) =>
    api.get(
      `/modlink?size=10&page=${currentPage}&sortBy=id&isAsc=false&searchType=${searchType}&keyword=${keyword}`
    ),
  deleteModLink: (data: { id: number[] }) => api.delete("/modlink", { data }), // delete method 에서는 body 값을 {} 로 한번 감싸주어야.
  editModLink: (
    id: number,
    data: {
      deviceType: string | undefined;
      serialNumber: string | undefined;
      macAddress: string | undefined;
      company: string | undefined;
      stage: number | undefined;
    }
  ) => api.put(`/modlink/${id}`, data),
  downloadExcel: () => api.get("/download-excel", { responseType: "blob" }),
  uploadExcel: (formData: any) =>
    api.post("/upload-excel", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),

  // SCAUTR COMPANY
  getScautrCompany: (
    currentPage: number,
    searchType: string,
    keyword: string
  ) =>
    api.get(
      `/company?size=10&page=${currentPage}&sortBy=supplierCode&isAsc=true&searchType=${searchType}&keyword=${keyword}`
    ),
  approveScautrCompany: (id: number) => api.put(`/company/${id}`),

  // DATA POINT
  getDataPointUser: (
    currentPage: number,
    searchType: string,
    keyword: string
  ) =>
    api.get(
      `/datapoint/user?size=10&page=${currentPage}&sortBy=createdat&isAsc=false&searchType=${searchType}&keyword=${keyword}`
    ),

  // PROGIX
  getProgixUser: (currentPage: number, searchType: string, keyword: string) =>
    api.get(
      `/progix?size=10&page=${currentPage}&sortBy=customerCode&isAsc=true&searchType=${searchType}&keyword=${keyword}`
    ),

  // 요금제 생성/관리
  getPlanList: (
    page: number,
    size: number,
    searchType: string,
    search: string
  ) =>
    api.get(
      `/plan?size=${size}&page=${page}&filter=${searchType}&search=${search}`
    ),
  postPlan: (data: FormValue) => api.post(`/plan`, data),
  editPlan: (data: { id: string; data: { description: string } }) =>
    api.put(`/plan/${data.id}`, data.data),

  // 디바이스 서비스 정보 등록
  getDeviceServiceList: (
    page: number,
    size: number,
    searchType: string,
    search: string
  ) =>
    api.get(
      `/device/plan?size=${size}&page=${page}&filter=${searchType}&search=${search}`
    ),
  checkValidMac: (mac: string) => api.get(`/device/plan/mac?mac=${mac}`),
  getAllPlans: () => api.get(`/device/plan/type`),
  postDeviceService: (data: {
    mac: string;
    freeTrialPeriod: number;
    planId: number[];
  }) => api.post(`/device/plan`, data),
  editDeviceService: (data: {
    id: string;
    data: {
      freeTrialPeriod: number;
      planId: number[];
    };
  }) => api.put(`/device/plan/${data.id}`, data.data),
};

export default apis;
