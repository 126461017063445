import Table from "../../../ui/table/Table";
import { IPayPlan } from "../../../../pages/Management/PlanManagement";
import { formatDate } from "../../../../shared/hook/date";
import { IoIosInformationCircle } from "react-icons/io";
import styled from "styled-components";

interface IPropsTableUnit {
  data: any;
  onClickPlan: (plan: IPayPlan) => () => void;
}

export default function TableUnit(props: IPropsTableUnit) {
  const { data, onClickPlan } = props;
  return (
    <Table>
      <Table.THead>
        <Table.TRow>
          <Table.TColumn.DefaultColumn size="lg">
            요금제명
          </Table.TColumn.DefaultColumn>
          <Table.TColumn.DefaultColumn size="sm">
            결제 타입
          </Table.TColumn.DefaultColumn>
          <Table.TColumn.DefaultColumn size="sm">
            결제 주기
          </Table.TColumn.DefaultColumn>
          <Table.TColumn.DefaultColumn size="sm">
            정상가(원)
          </Table.TColumn.DefaultColumn>
          <Table.TColumn.DefaultColumn size="sm">
            할인가(원)
            <ToolTip>
              <IoIosInformationCircle />
              <span className="tooltip">*최종 표시금액</span>
            </ToolTip>
          </Table.TColumn.DefaultColumn>
          <Table.TColumn.DefaultColumn size="lg">
            메모
          </Table.TColumn.DefaultColumn>
          <Table.TColumn.DefaultColumn size="lg">
            등록일
          </Table.TColumn.DefaultColumn>
          <Table.TColumn.DefaultColumn size="lg">
            최종 수정일
          </Table.TColumn.DefaultColumn>
          <Table.TColumn.DefaultColumn size="md">
            최종수정 담당자
          </Table.TColumn.DefaultColumn>
        </Table.TRow>
      </Table.THead>
      <Table.TBody>
        {data?.map((v: IPayPlan) => {
          return (
            <Table.TRow
              key={v.id}
              onClick={onClickPlan(v)}
              hoverActive
              style={{ cursor: "pointer" }}
            >
              <Table.TCell.DefaultCell>{v.title}</Table.TCell.DefaultCell>
              <Table.TCell.DefaultCell>
                {v.payType === "정기결제" ? "구독 결제" : "단건 결제"}
              </Table.TCell.DefaultCell>
              <Table.TCell.DefaultCell>
                {v.payInterval === 1 ? "1개월" : "1년"}
              </Table.TCell.DefaultCell>
              <Table.TCell.DefaultCell>
                {v.orgPrice.toLocaleString()}
              </Table.TCell.DefaultCell>
              <Table.TCell.DefaultCell>
                {v.price.toLocaleString()}
              </Table.TCell.DefaultCell>
              <Table.TCell.DefaultCell>{v.description}</Table.TCell.DefaultCell>
              <Table.TCell.DefaultCell>
                {formatDate(v.regDate)}
              </Table.TCell.DefaultCell>
              <Table.TCell.DefaultCell>
                {formatDate(v.modDate)}
              </Table.TCell.DefaultCell>
              <Table.TCell.DefaultCell>{v.modUserName}</Table.TCell.DefaultCell>
            </Table.TRow>
          );
        })}
      </Table.TBody>
    </Table>
  );
}

const ToolTip = styled.span`
  position: relative;
  margin-left: 2px;
  color: ${(props) => props.theme.color.gray};
  display: inline-flex;
  vertical-align: top;

  svg {
    width: 17px;
    height: 17px;
    cursor: pointer;
  }

  .tooltip {
    visibility: hidden;
    position: absolute;
    background-color: rgba(97, 97, 97, 0.7);
    color: #fff;
    text-align: center;
    border-radius: 3px;
    padding: 5px;
    top: -180%;
    left: 0;
    white-space: nowrap;
    z-index: 100;
    font-size: 1.2rem;
  }

  :hover {
    .tooltip {
      visibility: visible;
    }
  }
`;
