import React, { useState } from "react";
import styled, { ThemeProps } from "styled-components";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../../shared/cookie";
import PuffLoader from "react-spinners/PuffLoader";
import apis from "../../shared/apis";
import { handleError } from "../../components/ui/err/handleError";
import { AxiosError } from "axios";
import { AxiosCommonError } from "../../shared/types";
import { useMutation } from "@tanstack/react-query";
import Swal from "sweetalert2";

const SignUp = () => {
  // useNavigate 선언
  const navigate = useNavigate();

  // react-hook-form 타입정의
  interface FormProps {
    name: string;
    email: string;
    password: string;
    passwordCheck: string;
  }

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormProps>({ mode: "onChange" });

  const { mutate: mutateSignUp } = useMutation(apis.signUp, {
    onSuccess: () => {
      Swal.fire({
        icon: "success",
        title: "회원가입이 완료되었습니다.",
        text: "로그인 페이지로 이동합니다.",
      }).then(() => {
        navigate("/");
      });
    },
    onError: (error: AxiosError<AxiosCommonError>) => {
      handleError(error);
    },
  });

  // 폼 버튼 클릭시 작동하는 함수
  const onSubmit = async (data: FormProps) => {
    mutateSignUp(data);
  };

  // 캡스락 활성 여부 확인
  const [capslock, setCapsLock] = useState<boolean>(false);

  // password 정규식
  const passwordRegEx =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,16}$/;

  // 비밀번호 재확인
  const passwordRef = watch("password");

  return (
    <Wrap>
      <Container>
        <PuffLoader color="#fa5963" />
        <PostForm onSubmit={handleSubmit(onSubmit)}>
          <Line>
            <label htmlFor="">이름</label>
            <Input
              type="text"
              autoComplete="off"
              placeholder="이름을 입력해주세요"
              isInvalid={!!errors.name}
              {...register("name", {
                required: "이름을 입력해주세요",
              })}
            />
            {errors.name && <div className="err">{errors.name.message}</div>}
          </Line>
          <Line>
            <label htmlFor="email">이메일</label>
            <Input
              id="email"
              type="email"
              autoComplete="off"
              placeholder="이메일을 입력해주세요"
              isInvalid={!!errors.email}
              {...register("email", {
                required: "이메일을 입력해주세요",
              })}
            />
            {errors.email && <div className="err">{errors.email.message}</div>}
          </Line>
          <Line className="pw-line">
            <label htmlFor="password">비밀번호</label>
            <Input
              id="password"
              type="password"
              autoComplete="off"
              placeholder="영문/숫자/특수문자 포함 8~16자"
              isInvalid={!!errors.password}
              onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                e.getModifierState("CapsLock") === true
                  ? setCapsLock(true)
                  : setCapsLock(false);
              }}
              {...register("password", {
                required:
                  "영문,숫자,특수문자(!@#$%^&*)를 1개 이상 조합하여 입력해주세요",
                pattern: {
                  value: passwordRegEx,
                  message: "비밀번호 형식에 맞지 않습니다.",
                },
              })}
            />
            {errors.password && (
              <div className="err">{errors.password.message}</div>
            )}
            {capslock && (
              <div className="err capslock">CapsLock이 켜져 있습니다.</div>
            )}
            <Input
              className="pw-check-input"
              type="password"
              autoComplete="off"
              placeholder="비밀번호를 한번 더 입력해주세요"
              isInvalid={!!errors.passwordCheck}
              {...register("passwordCheck", {
                required: "비밀번호를 한번 더 입력해주세요",
                validate: (value) =>
                  value === passwordRef || "비밀번호가 동일하지 않습니다.",
              })}
            />
            {errors.passwordCheck && (
              <div className="err">{errors.passwordCheck.message}</div>
            )}
          </Line>
          <Button>
            <button className="signUp-email">이메일로 회원가입 하기</button>
          </Button>
        </PostForm>
        <div className="signUp-redirection">
          이미 계정이 있으신가요?{" "}
          <span
            onClick={() => {
              navigate("/");
            }}
          >
            로그인하기
          </span>
        </div>
      </Container>
    </Wrap>
  );
};

export default SignUp;
const Wrap = styled.main`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
`;
const Container = styled.main`
  font-size: 1.4rem;
  width: 360px;
  min-height: 660px;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .signUp-redirection {
    width: 100%;
    margin-top: 1rem;
    span {
      font-size: 1.5rem;
      text-decoration: underline;
      cursor: pointer;
      color: ${(props) => props.theme.color.purple};
    }
  }
`;
const PostForm = styled.form`
  width: 100%;
  margin-top: 2rem;
`;
const Line = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  .err {
    color: ${(props) => props.theme.color.error};
    font-size: 1.2rem;
    margin-top: 0.5rem;
  }
  .err.capslock {
    color: ${(props) => props.theme.color.blue};
  }
  label {
    margin-bottom: 1rem;
  }
  .pw-check-input {
    margin-top: 1rem;
  }
`;
const Input = styled.input`
  border: ${(props) => props.theme.border.gray1};
  width: 100%;
  border-radius: 3px;
  height: 5rem;
  padding: 1rem;
  font-size: 1.4rem;
  outline: ${(props: { isInvalid: boolean }) => props.isInvalid && "none"};
  border: ${(props: { isInvalid: boolean }) =>
    props.isInvalid && "1px solid red"};
  border-color: ${(props: { isInvalid: boolean }) =>
    props.isInvalid && "#fa5963"};
  &:focus {
    border: ${(props) => props.theme.border.blue2};
    box-shadow: 0 0 0 0.2rem ${(props) => props.theme.color.shadow.blue};
    outline: none;
  }
  &:focus::placeholder {
    color: transparent;
  }
`;
const Button = styled.div`
  margin-top: 2rem;
  button {
    width: 100%;
    height: 5rem;
    font-size: 1.5rem;
  }
  .signUp-email {
    background-color: ${(props) => props.theme.color.red};
    /* background-color: #f2f2f2;
    color: #5e5e5e; */
  }
`;
