import React from "react";
import styled from "styled-components";
import PuffLoader from "react-spinners/PuffLoader";
import { deleteCookie } from "../../../shared/cookie";

const ErrorComponent = () => {
  return (
    <Wrap>
      <PuffLoader color="#fa5963" />
      <div className="desc">
        <div>서버 재부팅, 혹은 보안상의 문제로</div>
        <div>
          <strong>엑세스 토큰 기간(30분)</strong>이 만료되었습니다.
        </div>
        <div>재로그인이 필요합니다.</div>
        <div className="desc-issue">
          관련 문의가 지속되면 담당부서에 연락바랍니다.
        </div>
      </div>
      <button
        onClick={() => {
          deleteCookie("Authorization");
          deleteCookie("RefreshToken");
        }}
      >
        레거시 토큰 삭제하기
      </button>
    </Wrap>
  );
};

export default ErrorComponent;
const Wrap = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .desc {
    font-size: 1.4rem;
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .desc-issue {
      margin-top: 1rem;
    }
  }
  button {
    margin-top: 2rem;
    font-size: 1.4rem;
    background-color: #fa5963;
  }
`;
