import styled from "styled-components";

interface IStyleProps {
  size?: "sm" | "md" | "lg";
  hoverActive?: boolean;
  pointer?: boolean;
  highlight?: boolean;
}

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const Th = styled.th<IStyleProps>`
  padding: 10px;
  background-color: #f6f7fb;
  border: 1px solid #e9edf3;
  min-width: ${(props) =>
    props.size === "sm" ? "5rem" : props.size === "md" ? "12rem" : "15rem"};
`;

export const Td = styled.td<IStyleProps>`
  padding: 10px;
  border: 1px solid #e9edf3;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  max-width: 210px;
  cursor: ${(props) => props.pointer && "pointer"};
  ${(props) =>
    props.highlight &&
    `
      &:hover {
        color: #35a3dc;
      }
    `};

  input {
    width: 70%;
    padding: 5px;
    text-align: center;
    border: 1px solid #e1e1e1;
    &:focus {
      border: 2px solid rgb(0, 123, 255);
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      outline: none;
    }
    &:focus::placeholder {
      color: transparent;
    }
  }
  input[type="checkbox"] {
    &:focus {
      border: none;
      box-shadow: none;
      outline: none;
    }
  }
  svg {
    font-size: 16px;
    cursor: pointer;
  }
`;

export const Tr = styled.tr<IStyleProps>`
  ${(props) =>
    props.hoverActive &&
    `
    &:hover {
      background-color: rgba(0, 123, 255, 0.1);
    }
  `}
`;
