import React, { useRef } from "react";
import { AiOutlinePaperClip } from "react-icons/ai";
import styled from "styled-components";
import apis from "../../../shared/apis";
import { useQueryClient } from "@tanstack/react-query";
import * as C from "../common/CommonStyles";

const ExcelModal = (props: {
  open: boolean;
  closeModal: () => void;
  header: string;
}) => {
  const { open, closeModal, header } = props;
  // file 객체를 담기위한 type='file'
  const fileObjectRef = useRef<HTMLInputElement | any>(null);
  // 첨부파일 제목 미리보기 표시
  const fileRef = useRef<HTMLInputElement | any>(null);
  const changeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0]; // null 체크 추가
    const fileName = file ? file.name : "";
    const fileType = fileName.slice(fileName.lastIndexOf(".") + 1);

    if (fileType === "xlsx" || fileType === "xls") {
      fileRef.current.value = e.target.value.substr(12);
    } else {
      alert("엑셀 파일 형식만 선택할 수 있습니다.");
    }
  };

  const getExcelTemplate = async () => {
    try {
      const res = await apis.downloadExcel();
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "출고등록 템플릿.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      alert("템플릿 다운로드에 실패했습니다.");
    }
  };

  // 쿼리 클라이언트 정의
  const queryClient = useQueryClient();

  const uploadExcelTemplate = async () => {
    const formData = new FormData();
    formData.append("file", fileObjectRef.current.files[0]);
    try {
      await apis.uploadExcel(formData);
      queryClient.invalidateQueries({ queryKey: ["loadModLinkQuery"] });
      closeModal();
      alert("등록이 완료되었습니다!");
    } catch (err: any) {
      alert(err.response.data);
    }
  };

  return (
    <React.Fragment>
      {open ? (
        <C.ModalWrap onClick={closeModal}>
          <C.Modal
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <ModalContent>
              <Title>{header}</Title>
              <UploadWrap>
                <div className="upload-input">
                  <input className="upload-file-name" ref={fileRef} readOnly />
                  <label htmlFor="fileUpload">
                    <AiOutlinePaperClip />
                  </label>
                </div>
                <div className="button-box">
                  <input
                    type="file"
                    accept=".xlsx, .xls"
                    id="fileUpload"
                    onChange={(e) => {
                      changeFile(e);
                    }}
                    ref={fileObjectRef}
                  />
                  <button
                    onClick={() => {
                      uploadExcelTemplate();
                    }}
                  >
                    업로드
                  </button>
                  <button
                    className="download"
                    onClick={() => {
                      getExcelTemplate();
                    }}
                  >
                    엑셀 템플릿 내려받기
                  </button>
                  <button className="close" onClick={closeModal}>
                    닫기
                  </button>
                </div>
              </UploadWrap>
            </ModalContent>
          </C.Modal>
        </C.ModalWrap>
      ) : null}
    </React.Fragment>
  );
};

export default ExcelModal;

const ModalContent = styled(C.ModalContent)`
  padding: 5px;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 10px;
`;

const UploadWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  width: 80%;
  gap: 10px;
  .upload-input {
    display: grid;
    grid-template-columns: 8.5fr 1.5fr;
    border: ${(props) => props.theme.border.gray1};

    input {
      padding: 5px;
      height: 35px;
      border: none;
      &:focus {
        border: ${(props) => props.theme.border.blue1};
        box-shadow: 0 0 0 0.2rem ${(props) => props.theme.color.shadow.blue};
        outline: none;
      }
    }
    label {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 20px;
      }
    }
  }
  #fileUpload {
    display: none;
  }
  .button-box {
    display: flex;
    justify-content: center;
    gap: 10px;
    .download {
      background-color: ${(props) => props.theme.color.green};
    }
    .close {
      background-color: ${(props) => props.theme.color.gray};
    }
  }
`;
