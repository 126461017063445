import Table from "../../../ui/table/Table";

interface IPropsTableUnit {
  data: any;
  approveRequestCheck: (impUid: string, amount: number) => () => void;
}

export default function TableUnit(props: IPropsTableUnit) {
  const { data, approveRequestCheck } = props;

  return (
    <Table>
      <Table.THead>
        <Table.TRow>
          <Table.TColumn.DefaultColumn size="lg">
            아이디
          </Table.TColumn.DefaultColumn>
          <Table.TColumn.DefaultColumn size="lg">
            이름
          </Table.TColumn.DefaultColumn>
          <Table.TColumn.DefaultColumn size="lg">
            연락처
          </Table.TColumn.DefaultColumn>
          <Table.TColumn.DefaultColumn size="lg">
            주문 내역
          </Table.TColumn.DefaultColumn>
          <Table.TColumn.DefaultColumn size="lg">
            주문번호
          </Table.TColumn.DefaultColumn>
          <Table.TColumn.DefaultColumn size="lg">
            결제번호
          </Table.TColumn.DefaultColumn>
          <Table.TColumn.DefaultColumn size="lg">
            디바이스 번호
          </Table.TColumn.DefaultColumn>
          <Table.TColumn.DefaultColumn size="lg">
            사유
          </Table.TColumn.DefaultColumn>
          <Table.TColumn.DefaultColumn size="lg">
            시간
          </Table.TColumn.DefaultColumn>
          <Table.TColumn.DefaultColumn size="lg">
            승인여부
          </Table.TColumn.DefaultColumn>
        </Table.TRow>
      </Table.THead>
      <Table.TBody>
        {data?.data.result.map((v: any, i: number) => {
          return (
            <Table.TRow key={i}>
              <Table.TCell.DefaultCell>{v.buyerEmail}</Table.TCell.DefaultCell>
              <Table.TCell.DefaultCell>{v.buyerName}</Table.TCell.DefaultCell>
              <Table.TCell.DefaultCell>{v.buyerTel}</Table.TCell.DefaultCell>
              <Table.TCell.DefaultCell>
                {v.name && v.name}
              </Table.TCell.DefaultCell>
              <Table.TCell.DefaultCell>{v.merchantUid}</Table.TCell.DefaultCell>
              <Table.TCell.DefaultCell>{v.impUid}</Table.TCell.DefaultCell>
              <Table.TCell.DefaultCell>{v.deviceId}</Table.TCell.DefaultCell>
              <Table.TCell.DefaultCell>
                {v.cancelReason}
              </Table.TCell.DefaultCell>
              <Table.TCell.DefaultCell>
                {v.createdAt.split("T")[0] + " " + v.createdAt.split("T")[1]}
              </Table.TCell.DefaultCell>
              <Table.TCell.DefaultCell
                onClick={approveRequestCheck(v.impUid, v.amount)}
                pointer
                highlight
              >
                승인대기
              </Table.TCell.DefaultCell>
            </Table.TRow>
          );
        })}
      </Table.TBody>
    </Table>
  );
}
