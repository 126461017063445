import Table from "../../../ui/table/Table";
interface IPropsTableUnit {
  data: any;
  onClickApproval: (approvalId: number, supplierCode: number) => () => void;
}

export default function TableUnit(props: IPropsTableUnit) {
  const { data, onClickApproval } = props;
  return (
    <Table>
      <Table.THead>
        <Table.TRow>
          <Table.TColumn.DefaultColumn size="md">
            회사코드
          </Table.TColumn.DefaultColumn>
          <Table.TColumn.DefaultColumn size="lg">
            회사명
          </Table.TColumn.DefaultColumn>
          <Table.TColumn.DefaultColumn size="lg">
            연락처
          </Table.TColumn.DefaultColumn>
          <Table.TColumn.DefaultColumn size="lg">
            사업자등록번호
          </Table.TColumn.DefaultColumn>
          <Table.TColumn.DefaultColumn size="md">
            대표자명
          </Table.TColumn.DefaultColumn>
          <Table.TColumn.DefaultColumn size="md">
            승인여부
          </Table.TColumn.DefaultColumn>
        </Table.TRow>
      </Table.THead>
      <Table.TBody>
        {data?.data.content.map((v: any, i: number) => {
          return (
            <Table.TRow key={i} hoverActive>
              <Table.TCell.DefaultCell>
                {v.supplierCode}
              </Table.TCell.DefaultCell>
              <Table.TCell.DefaultCell>{v.name}</Table.TCell.DefaultCell>
              <Table.TCell.DefaultCell>{v.phone}</Table.TCell.DefaultCell>
              <Table.TCell.DefaultCell>
                {v.registrationNumber}
              </Table.TCell.DefaultCell>
              <Table.TCell.DefaultCell>
                {v.representative}
              </Table.TCell.DefaultCell>
              <Table.TCell.DefaultCell
                onClick={onClickApproval(v.approvalId, v.supplierCode)}
                pointer={true}
                highlight
              >
                {v.approvalId === 11 ? "승인" : "미승인"}
              </Table.TCell.DefaultCell>
            </Table.TRow>
          );
        })}
      </Table.TBody>
    </Table>
  );
}
