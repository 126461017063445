import { useState } from "react";
import styled from "styled-components";
import Pagination from "../../components/ui/pagination/Pagination";
import apis from "../../shared/apis";
import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import ExcelModal from "../../components/ui/modal/ExcelModal";
import { AxiosError } from "axios";
import { AxiosCommonError } from "../../shared/types";
import { handleError } from "../../components/ui/err/handleError";
import RegistrationForm from "../../components/units/Management/Registration/RegistrationForm";
import SearchUnit from "../../components/units/Management/Registration/TableControls";
import TableUnit from "../../components/units/Management/Registration/TableUnit";

const MacAddress = () => {
  // 현재 페이지 상태값 및 시작 & 엑티브 페이지 상태값 저장
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [startPage, setStartPage] = useState<number>(1);
  const [active, setActive] = useState<string>("1");

  // 클라이언트단 url parameter 설정
  const [searchParams, setSearchParams] = useSearchParams("");
  const searchKeywordUrl = searchParams.get("search") || "";
  const searchTypeUrl = searchParams.get("searchType") || "all";

  const resetCheckBoxArr = () => {
    setCheckBoxArr([]);
  };

  const handleSearchParams = (url: string) => {
    setSearchParams(url);
  };

  // 검색 초기화시 페이지네이션 초기화
  const initPagination = () => {
    setCurrentPage(1);
    setStartPage(1);
    setActive("1");
  };

  // 출고된 모드링크 조회 쿼리
  const { data: getModLinkQuery } = useQuery(
    ["loadModLinkQuery", currentPage, searchTypeUrl, searchKeywordUrl],
    () => apis.getModLink(currentPage, searchTypeUrl, searchKeywordUrl),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (currentPage > data.data.totalPages) {
          // 현재 페이지가 총 페이지 수보다 크면
          setCurrentPage(data.data.totalPages); // 마지막 페이지로 이동
        }
      },
      onError: (error: AxiosError<AxiosCommonError>) => {
        handleError(error);
      },
    }
  );

  // 페이지네이션 처리를 위한 토탈값
  const total: number = getModLinkQuery?.data.totalElements;

  // 체크박스 개별 선택
  const [checkBoxArr, setCheckBoxArr] = useState<number[]>([]);

  // 등록 모달창 토글
  const [is_open, setIsOpen] = useState<boolean>(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <Wrap>
      <RegistrationBox>
        <div className="title">모드링크 출고등록</div>
        <RegistrationForm />
      </RegistrationBox>
      <ListBox>
        <SearchUnit
          searchKeywordUrl={searchKeywordUrl}
          searchTypeUrl={searchTypeUrl}
          total={total}
          openModal={openModal}
          initPagination={initPagination}
          resetCheckBoxArr={resetCheckBoxArr}
          checkBoxArr={checkBoxArr}
          handleSearchParams={handleSearchParams}
        />
        <TableWrap>
          <TableUnit
            data={getModLinkQuery}
            checkBoxArr={checkBoxArr}
            setCheckBoxArr={setCheckBoxArr}
            resetCheckBoxArr={resetCheckBoxArr}
          />
          {total > 0 ? (
            <Pagination
              total={total}
              size={10}
              setCurrentPage={setCurrentPage}
              startPage={startPage}
              setStartPage={setStartPage}
              active={active}
              setActive={setActive}
              setCheckBoxArr={setCheckBoxArr}
            />
          ) : null}
        </TableWrap>
      </ListBox>
      <ExcelModal
        open={is_open}
        closeModal={closeModal}
        header="모드링크 출고등록"
      />
    </Wrap>
  );
};

export default MacAddress;
const Wrap = styled.div``;
const RegistrationBox = styled.div`
  border: ${(props) => props.theme.border.gray2};
  padding: 1rem;
  .title {
    font-weight: 700;
    font-size: 1.8rem;
    margin-bottom: 1rem;
    padding: 1rem;
    border: ${(props) => props.theme.border.gray1};
    text-align: center;
  }
`;
const ListBox = styled.div`
  border: ${(props) => props.theme.border.gray2};
  padding: 1rem;
  margin: 2rem 0;
  overflow-x: auto;
`;

const TableWrap = styled.div`
  width: 100%;
  overflow-y: auto;
`;
