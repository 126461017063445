import Table from "../../../ui/table/Table";
import { BsSearch } from "react-icons/bs";

interface IPropsTableUnit {
  data: any;
  onClickNavigate: (id: string) => () => void;
}

export default function TableUnit(props: IPropsTableUnit) {
  const { data, onClickNavigate } = props;
  return (
    <Table>
      <Table.THead>
        <Table.TRow>
          <Table.TColumn.DefaultColumn size="lg">
            아이디
          </Table.TColumn.DefaultColumn>
          <Table.TColumn.DefaultColumn size="lg">
            이름
          </Table.TColumn.DefaultColumn>
          <Table.TColumn.DefaultColumn size="lg">
            연락처
          </Table.TColumn.DefaultColumn>
          <Table.TColumn.DefaultColumn size="lg">
            가입일
          </Table.TColumn.DefaultColumn>
          <Table.TColumn.DefaultColumn size="md">
            결제 내역
          </Table.TColumn.DefaultColumn>
        </Table.TRow>
      </Table.THead>
      <Table.TBody>
        {data?.data.content.map((v: any, i: number) => {
          return (
            <Table.TRow key={i}>
              <Table.TCell.DefaultCell>{v.userid}</Table.TCell.DefaultCell>
              <Table.TCell.DefaultCell>{v.username}</Table.TCell.DefaultCell>
              <Table.TCell.DefaultCell>{v.mobile}</Table.TCell.DefaultCell>
              <Table.TCell.DefaultCell>
                {v.createdat?.substr(0, 10)}
              </Table.TCell.DefaultCell>
              <Table.TCell.DefaultCell
                icon={<BsSearch />}
                onClick={onClickNavigate(v.userid)}
                highlight
              />
            </Table.TRow>
          );
        })}
      </Table.TBody>
    </Table>
  );
}
