/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import { NavLink, useNavigate, Link } from "react-router-dom";
import { FiExternalLink } from "react-icons/fi";
import {
  MdOutlineSpaceDashboard,
  MdOutlineManageAccounts,
} from "react-icons/md";
import { BsChevronDoubleLeft } from "react-icons/bs";
import { AiOutlineDoubleRight, AiOutlineCreditCard } from "react-icons/ai";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { CiSettings } from "react-icons/ci";
import { RiListSettingsFill } from "react-icons/ri";
import Swal from "sweetalert2";

const Sidebar = (props: {
  sideBarClose: boolean;
  sideBarDisplay: string;
  setSideBarClose: Dispatch<SetStateAction<boolean>>;
  setSideBarDisplay: Dispatch<SetStateAction<string>>;
}) => {
  const navigate = useNavigate();

  // 토글 리스트 타입 인터페이스
  interface IToggleList {
    deviceManagement: boolean;
    edgecrossService: boolean;
    datapointService: boolean;
    progixService: boolean;
    scautrService: boolean;
  }

  const [toggleList, setToggleList] = useState<IToggleList>({
    deviceManagement: false,
    edgecrossService: false,
    datapointService: false,
    progixService: false,
    scautrService: false,
  });

  return (
    <Wrap
      sideBarClose={props.sideBarClose}
      sideBarDisplay={props.sideBarDisplay}
    >
      {/* 상단 로고 타이틀 */}
      <Title sideBarClose={props.sideBarClose}>
        {/* --------- 사이드바 열기 --------- */}
        <AiOutlineDoubleRight
          onClick={() => {
            props.setSideBarClose(!props.sideBarClose);
          }}
        />
        <img
          src="/images/side_bar_logo.png"
          alt="엣지크로스 로고"
          onClick={() => {
            navigate("/admin/management/modlink");
          }}
        />
      </Title>
      {/* 타이틀 하단 메뉴바 */}
      <Navbar sideBarClose={props.sideBarClose} toggleList={toggleList}>
        <div className="menu-title">
          <span>관리자 메뉴</span>
          {/* --------- 사이드바 접기 --------- */}
          {/* 모바일 크기일 때, 사이드바 display block & none */}
          <div className="mobile">
            <BsChevronDoubleLeft
              onClick={() => {
                props.setSideBarDisplay("none");
              }}
            />
          </div>
          {/* 모바일 크기가 아닐 때, 사이드바 열고 접기 */}
          <div className="desktop">
            <BsChevronDoubleLeft
              onClick={() => {
                props.setSideBarClose(!props.sideBarClose);
                setToggleList((prev) => {
                  let newToogleList: IToggleList = { ...prev };
                  newToogleList["edgecrossService"] = false;
                  return newToogleList;
                });
              }}
            />
          </div>
        </div>
        <ul>
          {/* --------- li --------- */}
          <li
            onClick={() => {
              setToggleList((prev) => {
                let newToogleList: IToggleList = { ...prev };
                newToogleList["deviceManagement"] =
                  !newToogleList["deviceManagement"];
                return newToogleList;
              });
            }}
          >
            <Link to="#" onClick={(e) => e.preventDefault()}>
              <div className="svg-box">
                <MdOutlineSpaceDashboard />
              </div>
              <div className="menu-toggle">
                <span>디바이스 출고/요금 관리</span>
                {toggleList.deviceManagement ? (
                  <IoMdArrowDropup />
                ) : (
                  <IoMdArrowDropdown />
                )}
              </div>
            </Link>
          </li>
          {/* --------- li toggle box--------- */}
          <div className="menu-toggle-box-deviceManagement">
            <NavLink to="/admin/management/modlink">
              <span>출고등록</span>
            </NavLink>
            <NavLink to="/admin/management/plan">
              <span>요금제 생성/관리</span>
            </NavLink>
            <NavLink to="/admin/management/deviceService">
              <span>디바이스 서비스 정보 등록</span>
            </NavLink>
            {/* <a
              style={{ padding: "10px", color: "#ccc", cursor: "pointer" }}
              onClick={() => {
                Swal.fire({
                  icon: "info",
                  title: "서비스 준비중입니다.",
                });
              }}
            >
              <span>디바이스 서비스 정보 등록</span>
            </a> */}
          </div>
          {/* --------- li --------- */}
          <li
            onClick={() => {
              setToggleList((prev) => {
                let newToogleList: IToggleList = { ...prev };
                newToogleList["scautrService"] =
                  !newToogleList["scautrService"];
                return newToogleList;
              });
            }}
          >
            <Link to="#" onClick={(e) => e.preventDefault()}>
              <div className="svg-box">
                <CiSettings />
              </div>
              <div className="menu-toggle">
                <span>스카우터</span>
                {toggleList.scautrService ? (
                  <IoMdArrowDropup />
                ) : (
                  <IoMdArrowDropdown />
                )}
              </div>
            </Link>
          </li>
          {/* --------- li toggle box--------- */}
          <div className="menu-toggle-box-scautr">
            <NavLink to="/admin/scautrCompany">
              <span>회원관리</span>
            </NavLink>
          </div>
          {/* --------- li --------- */}
          <li
            onClick={() => {
              setToggleList((prev) => {
                let newToogleList: IToggleList = { ...prev };
                newToogleList["progixService"] =
                  !newToogleList["progixService"];
                return newToogleList;
              });
            }}
          >
            <Link to="#" onClick={(e) => e.preventDefault()}>
              <div className="svg-box">
                <MdOutlineManageAccounts />
              </div>
              <div className="menu-toggle">
                <span>프로직스</span>
                {toggleList.progixService ? (
                  <IoMdArrowDropup />
                ) : (
                  <IoMdArrowDropdown />
                )}
              </div>
            </Link>
          </li>
          {/* --------- li toggle box--------- */}
          <div className="menu-toggle-box-progix">
            <NavLink to="/admin/progix/member">
              <span>회원관리</span>
            </NavLink>
            <NavLink to="/admin/progix/payment">
              <span>결제내역</span>
            </NavLink>
            <NavLink to="/admin/progix/refund">
              <span>환불요청</span>
            </NavLink>
            <NavLink to="/admin/progix/refund-success">
              <span>환불내역</span>
            </NavLink>
          </div>
          {/* --------- li --------- */}
          {/* --------- li --------- */}
          <li
            onClick={() => {
              setToggleList((prev) => {
                let newToogleList: IToggleList = { ...prev };
                newToogleList["datapointService"] =
                  !newToogleList["datapointService"];
                return newToogleList;
              });
            }}
          >
            <Link to="#" onClick={(e) => e.preventDefault()}>
              <div className="svg-box">
                <AiOutlineCreditCard />
              </div>
              <div className="menu-toggle">
                <span>데이터 포인트</span>
                {toggleList.datapointService ? (
                  <IoMdArrowDropup />
                ) : (
                  <IoMdArrowDropdown />
                )}
              </div>
            </Link>
          </li>
          {/* --------- li toggle box--------- */}
          <div className="menu-toggle-box-datapoint">
            <NavLink to="/admin/datapoint/member">
              <span>회원관리</span>
            </NavLink>
            <NavLink to="/admin/datapoint/payment">
              <span>결제내역</span>
            </NavLink>
            <NavLink to="/admin/datapoint/refund">
              <span>환불요청</span>
            </NavLink>
            <NavLink to="/admin/datapoint/refund-success">
              <span>환불내역</span>
            </NavLink>
          </div>
          {/* --------- li --------- */}
          <li
            onClick={() => {
              setToggleList((prev) => {
                let newToogleList: IToggleList = { ...prev };
                newToogleList["edgecrossService"] =
                  !newToogleList["edgecrossService"];
                return newToogleList;
              });
            }}
          >
            <Link to="#" onClick={(e) => e.preventDefault()}>
              <div className="svg-box">
                <FiExternalLink />
              </div>
              <div className="menu-toggle">
                <span>엣지크로스 서비스</span>
                {toggleList.edgecrossService ? (
                  <IoMdArrowDropup />
                ) : (
                  <IoMdArrowDropdown />
                )}
              </div>
            </Link>
          </li>
          {/* --------- li toggle box--------- */}
          <div className="menu-toggle-box-edgecross-service">
            <a
              href="https://scautr.edgecross.ai/"
              target="_blank"
              rel="noreferrer"
            >
              <span>스카우터 바로가기</span>
            </a>
            <a
              href="https://progix.edgecross.ai/"
              target="_blank"
              rel="noreferrer"
            >
              <span>프로직스 바로가기</span>
            </a>
            <a
              href="https://relaypoint.edgecross.ai/"
              target="_blank"
              rel="noreferrer"
            >
              <span>릴레이포인트 바로가기</span>
            </a>
            <a
              href="https://www.v-ola.com/auth/login.do?returnto=/"
              target="_blank"
              rel="noreferrer"
            >
              <span>V-OLA 바로가기</span>
            </a>
          </div>
          {/* --------- li --------- */}
          {/* <li>
            <a
              href="https://api.scautr.edgecross.ai/admin/modlink"
              target="_blank"
              rel="noreferrer"
            >
              <div className="svg-box">
                <RiListSettingsFill />
              </div>
              <span>모드링크 템플릿 설정</span>
            </a>
          </li> */}
        </ul>
      </Navbar>
    </Wrap>
  );
};

export default Sidebar;

const Wrap = styled.div`
  position: fixed;
  /* 사이드바 닫기 클릭 여부에 따라서 width 조정 */
  width: ${(props: { sideBarClose: boolean }) =>
    props.sideBarClose ? "6rem" : "23rem"};
  overflow: hidden;
  white-space: nowrap;
  transition: all 0.2s ease-in-out;
  height: 100%;
  background-color: ${(props) => props.theme.color.bg[600]};
  color: ${(props) => props.theme.color.white};
  font-size: 1.4rem;
  /* (반응형) 570px 보다 낮은 화면에서는 사이드바가 none */
  @media (max-width: ${(props) => props.theme.breakpoints.Mobile}) {
    display: ${(props: { sideBarClose: boolean; sideBarDisplay: string }) =>
      props.sideBarDisplay};
  }
  overflow-y: auto;
`;
const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  height: 11.5rem;
  img {
    width: 20rem;
    cursor: pointer;
    display: ${(props: { sideBarClose: boolean }) =>
      props.sideBarClose ? "none" : "inline"};
  }
  svg {
    cursor: pointer;
    font-size: 2rem;
    display: ${(props: { sideBarClose: boolean }) =>
      props.sideBarClose ? "block" : "none"};
  }
`;
const Navbar = styled.nav`
  margin-top: 2rem;
  .menu-title {
    color: ${(props) => props.theme.color.gray};
    padding: 10px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:hover {
      svg {
        color: ${(props) => props.theme.color.white};
      }
    }
    svg {
      cursor: pointer;
    }
    visibility: ${(props: { sideBarClose: boolean }) =>
      props.sideBarClose ? "hidden" : "visible"};
  }
  .mobile {
    @media (min-width: ${(props) => props.theme.breakpoints.Mobile}) {
      display: none;
    }
  }
  .desktop {
    @media (max-width: ${(props) => props.theme.breakpoints.Mobile}) {
      display: none;
    }
  }
  ul {
    list-style: none;
    li {
      cursor: pointer;
      svg {
        color: ${(props) => props.theme.color.gray};
        font-size: 2rem;
      }
      &:hover {
        background-color: #222;
        svg {
          color: ${(props) => props.theme.color.white};
        }
      }
      .svg-box {
        margin-right: 20px;
      }
      .menu-toggle {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }
      a {
        display: flex;
        align-items: center;
        padding: 15px;
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
        &.active {
          background-color: ${(props) => props.theme.color.blue};
          svg {
            color: ${(props) => props.theme.color.white};
          }
        }
      }
    }
    .menu-toggle-box-datapoint {
      transition: all 0.2s ease-in-out;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      max-height: ${(props: { sideBarClose: boolean; toggleList: any }) =>
        props.toggleList.datapointService ? "220px" : "0"};
      a {
        padding: 10px;
        color: #ccc;
        span {
          padding-left: 50px;
        }
        &:hover {
          background-color: #222;
        }
        &.active {
          background-color: ${(props) => props.theme.color.blue};
          color: ${(props) => props.theme.color.white};
        }
      }
    }
    .menu-toggle-box-edgecross-service {
      transition: all 0.2s ease-in-out;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      max-height: ${(props: { sideBarClose: boolean; toggleList: any }) =>
        props.toggleList.edgecrossService ? "220px" : "0"};
      a {
        padding: 10px;
        color: #ccc;
        span {
          padding-left: 50px;
        }
        &:hover {
          background-color: #222;
        }
      }
    }
    .menu-toggle-box-progix {
      transition: all 0.2s ease-in-out;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      max-height: ${(props: { sideBarClose: boolean; toggleList: any }) =>
        props.toggleList.progixService ? "220px" : "0"};
      a {
        padding: 10px;
        color: #ccc;
        span {
          padding-left: 50px;
        }
        &:hover {
          background-color: #222;
        }
        &.active {
          background-color: ${(props) => props.theme.color.blue};
          color: ${(props) => props.theme.color.white};
        }
      }
    }
    .menu-toggle-box-deviceManagement {
      transition: all 0.2s ease-in-out;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      max-height: ${(props: { sideBarClose: boolean; toggleList: any }) =>
        props.toggleList.deviceManagement ? "220px" : "0"};
      a {
        padding: 10px;
        color: #ccc;
        span {
          padding-left: 50px;
        }
        &:hover {
          background-color: #222;
        }
        &.active {
          background-color: ${(props) => props.theme.color.blue};
          color: ${(props) => props.theme.color.white};
        }
      }
    }

    .menu-toggle-box-scautr {
      transition: all 0.2s ease-in-out;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      max-height: ${(props: { sideBarClose: boolean; toggleList: any }) =>
        props.toggleList.scautrService ? "220px" : "0"};
      a {
        padding: 10px;
        color: #ccc;
        span {
          padding-left: 50px;
        }
        &:hover {
          background-color: #222;
        }
        &.active {
          background-color: ${(props) => props.theme.color.blue};
          color: ${(props) => props.theme.color.white};
        }
      }
    }
  }
`;
