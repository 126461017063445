import React from "react";
import { Td } from "./styles";

interface IPropsTCell extends React.HTMLAttributes<HTMLTableCellElement> {
  children?: React.ReactNode;
  onClick?: () => void;
  icon?: React.ReactNode;
  pointer?: boolean;
  isChecked?: boolean;
  handleCheckBox?: (a: any) => void;
  inputId?: number | string;
  highlight?: boolean;
}

const DefaultCell = ({
  children,
  icon,
  onClick,
  pointer = false,
  highlight = false,
  ...props
}: IPropsTCell) => {
  return (
    <Td
      onClick={onClick ? onClick : () => {}}
      pointer={pointer}
      highlight={highlight}
      {...props}
    >
      {icon && icon}
      {children}
    </Td>
  );
};

const CheckboxCell = ({
  children,
  isChecked,
  handleCheckBox,
  inputId,
  ...props
}: IPropsTCell) => {
  return (
    <Td pointer={false} {...props}>
      <input
        type="checkbox"
        checked={isChecked}
        onChange={handleCheckBox}
        id={String(inputId)}
      />
      {children}
    </Td>
  );
};

const TCell = {
  DefaultCell,
  CheckboxCell,
};

export default TCell;
