import styled from "styled-components";
import PuffLoader from "react-spinners/PuffLoader";
import { useParams } from "react-router-dom";

const DataPointDetail = () => {
  // url에 id값 받아오기
  const view = useParams();

  return (
    <Wrap>
      <PuffLoader color="#fa5963" />
      <div className="desc">
        <div className="bold">{view.userId}</div>
        <div>결제내역 DB 연동은 개발 진행중입니다.</div>
      </div>
    </Wrap>
  );
};

export default DataPointDetail;
const Wrap = styled.div`
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .desc {
    .bold {
      font-weight: 700;
    }
    line-height: 1.4;
    font-size: 1.4rem;
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`;
