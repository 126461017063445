import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import apis from "../../../../shared/apis";
import Swal from "sweetalert2";
import { AxiosError } from "axios";
import { AxiosCommonError } from "../../../../shared/types";
import { handleError } from "../../../ui/err/handleError";

interface FormValues {
  type: string;
  serialNumber: string;
  macAddress: string;
  company: string;
  stage: string;
}

export default function RegistrationForm() {
  // 신규 등록용 form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    mode: "onTouched",
    defaultValues: { stage: "1" }, // 실출고 : 1, 테스트 : 0
  });

  const queryClient = useQueryClient();

  // 신규 모드링크 출고 등록
  const { mutate: postMutate } = useMutation(apis.RegistrationModLink, {
    onSuccess: () => {
      Swal.fire({
        icon: "success",
        title: "등록이 완료되었습니다.",
      });
      queryClient.invalidateQueries({ queryKey: ["loadModLinkQuery"] });
      reset();
    },
    onError: (error: AxiosError<AxiosCommonError>) => {
      handleError(error);
    },
  });

  // 폼 버튼 클릭시 동작하는 함수
  const onSubmit = async (data: FormValues) => {
    const sendData = {
      company: data.company,
      deviceType: data.type,
      macAddress: data.macAddress,
      serialNumber: data.serialNumber,
      stage: Number(data.stage),
    };

    postMutate(sendData);
  };

  return (
    <PostForm onSubmit={handleSubmit(onSubmit)}>
      <Column>
        <Line>
          <label htmlFor="type">모드링크 종류</label>
          <select {...register("type")}>
            <option value="MODLINK-T">MODLINK-T</option>
            <option value="MODLINK-T2">MODLINK-T2</option>
            <option value="MODLINK-B">MODLINK-B</option>
            <option value="MODLINK-L">MODLINK-L</option>
            <option value="AP-D2">AP-D2</option>
            <option value="DATAPOINT-P2">DATAPOINT-P2</option>
            <option value="DATAPOINT-P4">DATAPOINT-P4</option>
          </select>
          {errors.type && <Error>{errors.type.message}</Error>}
        </Line>
        <Line>
          <label htmlFor="serialNumber">S/N</label>
          <Input
            type="text"
            autoComplete="off"
            placeholder={"시리얼 번호를 입력해주세요"}
            isInvalid={!!errors.serialNumber}
            id="serialNumber"
            {...register("serialNumber", {
              required: "시리얼 번호를 입력해주세요",
            })}
          />
          {errors.serialNumber && <Error>{errors.serialNumber.message}</Error>}
        </Line>
        <Line>
          <label htmlFor="macAddress">MAC</label>
          <Input
            type="text"
            autoComplete="off"
            placeholder={"맥주소를 입력해주세요"}
            isInvalid={!!errors.macAddress}
            id="macAddress"
            {...register("macAddress", {
              required: false,
            })}
          />
        </Line>
        <Line>
          <label htmlFor="company">출고 기업</label>
          <Input
            type="text"
            autoComplete="off"
            placeholder={"출고된 기업명을 입력해주세요"}
            isInvalid={!!errors.company}
            id="company"
            {...register("company", {
              required: "출고된 기업명을 입력해주세요",
            })}
          />
          {errors.company && <Error>{errors.company.message}</Error>}
        </Line>
        <Line>
          <p>출고 종류</p>
          <RadioBtnBox>
            <input type="radio" id="actual" value="1" {...register("stage")} />
            <label htmlFor="actual">실출고</label>
            <input type="radio" id="test" value="0" {...register("stage")} />
            <label htmlFor="test">테스트</label>
          </RadioBtnBox>
        </Line>
      </Column>
      <button type="submit">등록</button>
      <Notice>
        ※ 테스트용 MAC 등록 시 출고 종류는 “테스트” 로 표시, 출고기업에는 테스트
        목적을 간략하게 적어주세요.
      </Notice>
    </PostForm>
  );
}

const PostForm = styled.form`
  border: 1px solid #e9edf3;
  padding: 3rem 3rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    width: 10rem;
    height: 4rem;
    font-size: 1.4rem;
    margin-top: 2rem;
  }
`;

const Column = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
`;

const Line = styled.div`
  display: flex;
  flex-direction: column;
  > label,
  > p {
    font-weight: 600;
    margin-bottom: 1rem;
  }

  select {
    border: 1px solid #e1e1e1;
    height: 4rem;
    padding: 1rem;
    width: 20rem;
  }
`;

const Error = styled.div`
  color: red;
  font-size: 1.2rem;
  margin-top: 0.5rem;
`;

const RadioBtnBox = styled.div`
  width: 20rem;
  margin-top: 1rem;

  input {
    margin-right: 0.6rem;
    width: 1.6rem;
    height: 1.6rem;
    cursor: pointer;
  }

  label {
    padding-right: 1rem;
    margin-right: 2rem;
    vertical-align: top;
    cursor: pointer;
  }
`;

const Input = styled.input`
  border: 1px solid #e1e1e1;
  height: 4rem;
  padding: 1rem;
  width: 20rem;
  border: ${(props: { isInvalid?: boolean }) =>
    props.isInvalid && "1px solid red"};
  border-color: ${(props: { isInvalid?: boolean }) =>
    props.isInvalid && "#fa5963"};
  &:focus {
    border: 2px solid rgb(0, 123, 255);
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    outline: none;
  }
  &:focus::placeholder {
    color: transparent;
  }
`;

const Notice = styled.p`
  width: 100%;
  font-size: 1.2rem;
  margin-top: 2rem;
  color: #666;
  text-align: right;

  @media (max-width: 860px) {
    width: 80%;
    text-align: center;
    line-height: 1.5;
  }
`;
