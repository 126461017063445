import React, { useState } from 'react';
import styled from 'styled-components';
import Header from './Header';
import Sidebar from './Sidebar';
import { Outlet } from 'react-router-dom';

const Layout = () => {
  // 사이드바 관리
  const [sideBarClose, setSideBarClose] = useState<boolean>(false);
  // 모바일 반응형 사이드바 핸들링
  const [sideBarDisplay, setSideBarDisplay] = useState<string>('none');

  return (
    <React.Fragment>
      <Header
        sideBarClose={sideBarClose}
        sideBarDisplay={sideBarDisplay}
        setSideBarDisplay={setSideBarDisplay}
      />
      <Sidebar
        sideBarClose={sideBarClose}
        sideBarDisplay={sideBarDisplay}
        setSideBarClose={setSideBarClose}
        setSideBarDisplay={setSideBarDisplay}
      />
      <Main sideBarClose={sideBarClose}>
        <Outlet />
      </Main>
    </React.Fragment>
  );
};

export default Layout;
const Main = styled.main`
  padding-left: ${(props: { sideBarClose: boolean }) =>
    props.sideBarClose ? '8rem' : '25rem'};
  transition: all 0.2s ease-in-out;
  padding-right: 2rem;
  padding-top: 7rem;
  width: 100%;
  min-height: 100vh;
  font-size: 1.4rem; // 기본 1rem이 10px로 지정되어있기 때문에, Outlet을 감싸주면서 font-size를 1.6rem 지정해주기
  @media (max-width: ${(props) => props.theme.breakpoints.Mobile}) {
    width: 100%;
    height: 100%;
    padding-left: 2rem;
  }
`;
