import React from "react";
import { Th } from "./styles";

interface IPropsTColumn extends React.HTMLAttributes<HTMLTableCellElement> {
  children?: React.ReactNode;
  size: "sm" | "md" | "lg";
  isChecked?: boolean;
  handleCheckBox?: (a: any) => void;
}

function DefaultColumn({ children, size, ...props }: IPropsTColumn) {
  return (
    <Th size={size} {...props}>
      {children}
    </Th>
  );
}

function CheckboxColumn({
  children,
  isChecked,
  handleCheckBox,
  size,
  ...props
}: IPropsTColumn) {
  return (
    <Th size={size} {...props}>
      <input type="checkbox" checked={isChecked} onChange={handleCheckBox} />
      {children}
    </Th>
  );
}

const TColumn = {
  DefaultColumn,
  CheckboxColumn,
};

export default TColumn;
