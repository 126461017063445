import React, { forwardRef } from "react";
import { Table as StyleTable } from "./unit/styles";
import THead from "./unit/Thead";
import TBody from "./unit/TBody";
import TRow from "./unit/TRow";
import TColumn from "./unit/TColumn";
import TCell from "./unit/TCell";

interface IPropsTable extends React.HTMLAttributes<HTMLTableElement> {
  children: React.ReactNode;
  ref?: React.RefObject<HTMLTableElement>;
}

// forwardRef로 ref를 전달
const TableComponent = forwardRef<HTMLTableElement, IPropsTable>(
  ({ children, ...props }, ref) => {
    return (
      <StyleTable ref={ref} {...props}>
        {children}
      </StyleTable>
    );
  }
);

const Table = Object.assign(TableComponent, {
  THead,
  TBody,
  TRow,
  TColumn,
  TCell,
});

export default Table;
